export const getNestedValue = (obj: any, path: string): any => {
  return path.split(".").reduce((value, key) => value[key], obj);
};

export const formatCurrency = (price: number): string => {
  if (isNaN(price)) {
    return "-";
  }

  let abbreviatedPrice = "";
  const absPrice = Math.abs(price); // To handle both positive and negative numbers

  if (absPrice >= 1e9) {
    abbreviatedPrice = (price / 1e9).toFixed(2) + " B"; // Billion
  } else if (absPrice >= 1e6) {
    abbreviatedPrice = (price / 1e6).toFixed(2) + " M"; // Million
  } else if (absPrice >= 1e3) {
    abbreviatedPrice = (price / 1e3).toFixed(2) + " k"; // Thousand
  } else {
    abbreviatedPrice = price.toFixed(2); // Less than thousand
  }

  // Split the price into integer and decimal parts for formatting
  const [integerPart, decimalPart] = abbreviatedPrice.split(".");

  // Add comma separators to the integer part if needed
  const withCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Combine the integer part with commas and the decimal part
  return decimalPart ? `${withCommas}.${decimalPart}` : withCommas;
};

export const generateQuarterArray = (
  quarter: string,
  count: number,
): string[] => {
  const quarters = ["03", "06", "09", "12"];
  const [yearStr, monthStr] = quarter.split("/");
  let year = parseInt(yearStr, 10);

  // Determine the initial quarter index
  let quarterIndex = quarters.indexOf(monthStr.padStart(2, "0"));

  if (quarterIndex === -1) {
    throw new Error("Invalid quarter format.");
  }

  const result: string[] = [];

  for (let i = 0; i < count; i++) {
    // Add the current quarter to the result array
    const quarterName = `Q${quarterIndex + 1}`;
    result.push(`${quarterName}-${year.toString().slice(-2)}`);

    // Move to the previous quarter
    quarterIndex--;

    // If we go past the first quarter, move to the previous year and reset the quarter index
    if (quarterIndex < 0) {
      quarterIndex = 3;
      year--;
    }
  }

  return result;
};

import React from "react";
import { Button, Col, Drawer, Row, Typography, Space } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import FileUploader from "../../../components/FileUploader";
import { useApp } from "../../../context/app.context";

const { Title } = Typography;

interface CreateFirmDrawerAnalysisProps {
  open: boolean;
  onClose: () => void;
}

const CreateFirmDrawerAnalysis: React.FC<CreateFirmDrawerAnalysisProps> = ({
  open,
  onClose,
}) => {
  const {
    selectedFirm,
    createFirmAnalysis,
    loadingCreateFirmAnalysis,
    file,
    updateFile,
  } = useApp();

  const submitFirmAnalysis = async () => {
    if (selectedFirm && file) {
      const payload = {
        firm_id: selectedFirm.id,
        file,
      };
      try {
        await createFirmAnalysis(payload);
        onClose();
        updateFile(null);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Drawer
      title="Create a New Financial Analysis"
      width={500}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      <Title level={3}>{selectedFirm?.name}</Title>
      <Row gutter={32}>
        <Col>
          <Title level={5}>File</Title>
          <FileUploader
            accept=".xlsx, .xls, .csv"
            description="File"
            setFile={updateFile}
          />
        </Col>
      </Row>
      <Row>
        <Space style={{ marginTop: 20 }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="primary"
            disabled={!file || loadingCreateFirmAnalysis || !selectedFirm}
            onClick={submitFirmAnalysis}
          >
            {loadingCreateFirmAnalysis && <LoadingOutlined />} Upload
          </Button>
        </Space>
      </Row>
    </Drawer>
  );
};

export default CreateFirmDrawerAnalysis;

import React from "react";
import { Card, Col } from "antd";

const { Meta } = Card;

interface ProductCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const ProductCard: React.FC<ProductCardProps> = ({
  icon,
  title,
  description,
  onClick,
  style,
}) => {
  return (
    <Col>
      <Card
        hoverable={!!onClick}
        onClick={onClick}
        style={{ width: 300, ...style }}
      >
        <Meta avatar={icon} title={title} description={description} />
      </Card>
    </Col>
  );
};

export default ProductCard;

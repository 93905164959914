import { message } from "antd";
import {
  ILLMAnalysisRequest,
  ILLMAnalysis,
} from "../../containers/LLMAnalysis/types";
import BackendClient from "../BackendClient";
import { LLMAnalysisUrl } from "../constants/urls";

export const createLLMAnalysisRequest = async (
  payload: ILLMAnalysisRequest,
): Promise<ILLMAnalysis> => {
  try {
    const { data } = await BackendClient.post(
      LLMAnalysisUrl.CreateLLMAnalysis,
      payload,
    );
    message.success("LLM Analysis created successfully");
    return data;
  } catch (error) {
    console.error(error);
    message.error("Failed to create LLM analysis. Please try again later.");
    throw error;
  }
};

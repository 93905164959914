import React from "react";
import { Layout, Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { Url } from "../../constants/urls";
import { useApp } from "../../context/app.context";
import {
  AppstoreOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;

const SideMenu: React.FC = () => {
  const { sideMenuCollapsed } = useApp();
  const navigate = useNavigate();
  const location = useLocation();

  const sideMenuItems = [
    {
      key: Url.ProductsRoute,
      icon: <AppstoreOutlined />,
      label: "Products",
    },
    {
      key: Url.FirmsRoute,
      icon: <ApartmentOutlined />,
      label: "Firms",
    },
  ];

  return (
    <Sider trigger={null} collapsible collapsed={sideMenuCollapsed} width="160">
      <div className="demo-logo-vertical" />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[Url.ProductsRoute]}
        selectedKeys={
          location.pathname.includes(Url.ProductsRoute)
            ? [Url.ProductsRoute]
            : [location.pathname]
        }
        onSelect={({ key }) => navigate(key)}
        items={sideMenuItems}
      />
    </Sider>
  );
};

export default SideMenu;

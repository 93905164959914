import React, { useRef } from "react";
import { Button } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FilePdfOutlined } from "@ant-design/icons";
import { useApp } from "../../context/app.context";

interface GeneratePdfProps {
  children: React.ReactElement;
  firmId: number;
  analysisId: number;
}

const GeneratePdf: React.FC<GeneratePdfProps> = ({
  children,
  firmId,
  analysisId,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const {
    fetchFirmAnalysis,
    createLLMAnalysis,
    isPdfGenerating,
    setIsPdfGenerating,
  } = useApp();

  const handleDownloadPdf = async () => {
    setIsPdfGenerating(true); // Ensure the element is visible for PDF generation
    await Promise.all([
      fetchFirmAnalysis(firmId, analysisId),
      createLLMAnalysis({ firm_id: firmId }),
    ]);
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for the element to become visible
    if (componentRef.current) {
      const element = componentRef.current;
      const canvas = await html2canvas(element);

      const pdf = new jsPDF("p", "pt", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      const pageHeightInCanvas = pdfHeight * (canvasWidth / pdfWidth);
      let heightLeft = canvasHeight;
      let position = 0;

      while (heightLeft > 0) {
        const canvasPage = document.createElement("canvas");
        const context = canvasPage.getContext("2d");

        canvasPage.width = canvasWidth;
        canvasPage.height = Math.min(pageHeightInCanvas, heightLeft);

        context?.drawImage(
          canvas,
          0,
          position,
          canvasWidth,
          canvasPage.height,
          0,
          0,
          canvasWidth,
          canvasPage.height,
        );

        const pageData = canvasPage.toDataURL("image/jpeg", 0.8);
        if (position > 0) {
          pdf.addPage();
        }

        // Add image with some error handling
        try {
          pdf.addImage(
            pageData,
            "JPEG",
            0,
            0,
            pdfWidth,
            (canvasPage.height / canvasWidth) * pdfWidth,
            undefined,
            "FAST",
          );
        } catch (error) {
          console.error("Error adding image to PDF:", error);
        }

        heightLeft -= pageHeightInCanvas;
        position += pageHeightInCanvas;
      }

      const currentDate = new Date().toISOString().slice(0, 10); // Format as YYYY-MM-DD
      pdf.save(`${firmId}_report#${analysisId}_${currentDate}.pdf`);
      setIsPdfGenerating(false); // Hide the element after PDF generation
    }
  };

  return (
    <div>
      <Button type="link" onClick={handleDownloadPdf} danger>
        <FilePdfOutlined /> Generate Financial Report
      </Button>
      <div
        style={{
          letterSpacing: "0.02em",
          position: "absolute",
          top: "-9999px",
          left: "-9999px",
        }}
      >
        {React.cloneElement(children, {
          ref: componentRef,
          style: {
            display: isPdfGenerating ? "block" : "none",
            width: "1600px", // Fixed width for consistent rendering (A4 width in pixels)
            height: "9000px", // Fixed height for consistent rendering (A4 height in pixels)
            padding: "0 40px",
            backgroundColor: "#f8f8fa",
          },
        })}
      </div>
    </div>
  );
};

export default GeneratePdf;

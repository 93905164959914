import { message } from "antd";
import {
  IFeedbackRequest,
  IFeedback,
} from "../../containers/Feedback/types/feedback";
import BackendClient from "../BackendClient";
import { FeedbackUrl } from "../constants/urls";

export const createFeedbackRequest = async (
  payload: IFeedbackRequest,
): Promise<IFeedback> => {
  try {
    const { data } = await BackendClient.post(
      FeedbackUrl.CreateFeedback,
      payload,
    );
    message.success("Feedback submitted successfully");
    return data;
  } catch (error) {
    console.error(error);
    message.error("Failed to submit feedback. Please try again later.");
    throw error;
  }
};

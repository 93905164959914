import { Line } from "@ant-design/plots";
import React from "react";

interface LineChartData {
  [key: string]: any;
}

interface LineChartProps {
  data: LineChartData[];
  xField: string;
  yField: string;
  shapeField?: "line" | "vh" | "smooth";
  title?: string;
  yAxisDescription?: string;
  xAxisDescription?: string;
  suffix?: string;
  height?: number;
  marginTop?: number;
  formatter?: (text: any) => string;
}

const LineChart: React.FC<LineChartProps> = ({
  data,
  xField,
  yField,
  shapeField = "line",
  title,
  yAxisDescription,
  xAxisDescription,
  suffix = "",
  height,
  marginTop = 0,
  formatter = (text) => text,
}) => {
  const customFormatter = (text: string) =>
    suffix ? `${formatter(text)}${suffix}` : formatter(text);

  const config = {
    data,
    xField,
    yField,
    shapeField,
    point: {
      sizeField: "2",
    },
    interaction: {
      tooltip: {
        marker: false,
      },
    },
    style: {
      lineWidth: 2,
    },
    title: {
      title,
      align: "center",
    },
    axis: {
      y: {
        title: yAxisDescription,
        labelFormatter: (val: string) => customFormatter(val),
      },
      x: {
        title: xAxisDescription,
      },
    },
    height,
  };
  return (
    <div style={{ marginTop }}>
      <Line {...config} />
    </div>
  );
};

export default LineChart;

import { generatePath } from "react-router-dom";

export const AuthenticationUrl = {
  Register: "/registration/",
  Login: "/login/",
};

export const UserUrl = {
  GetUser: "/user",
};

export const FeedbackUrl = {
  CreateFeedback: "/feedback/",
};

export const FirmsUrl = {
  GetFirms: "/firm/",
  GetFirm: (params: { id: string }) => generatePath("/firm/:id/", params),
  CreateFirm: "/firm/",
  UpdateFirm: (params: { id: string }) => generatePath("/firm/:id/", params),
  DeleteFirm: (params: { id: string }) => generatePath("/firm/:id/", params),
};

export const SectorsUrl = {
  GetSectors: "/sector/",
};

export const FirmAnalysisUrl = {
  GetFirmAnalyses: (params: { id: string }) =>
    `/finanalysis/?firm_id=${params.id}`,
  GetFirmAnalysis: (params: { id: string }) =>
    generatePath("/finanalysis/:id/", params),
  CreateFirmAnalysis: "/analyze/",
  DeleteFirmAnalysis: (params: { id: string }) =>
    generatePath("/finanalysis/:id/", params),
};

export const LLMAnalysisUrl = {
  CreateLLMAnalysis: "/llm-analyze/",
};

import React, { useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Card, Progress, Typography, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import GaugeChart from "../../../../components/Charts/GaugeChart";
import ColumnChart from "../../../../components/Charts/ColumnChart";
import { useApp } from "../../../../context/app.context";
import {
  formatCurrency,
  generateQuarterArray,
} from "../../../../utils/helpers";
import "../index.css";

const { Title } = Typography;

interface SolvencyProps {
  isPdfReport?: boolean;
}

const Solvency: React.FC<SolvencyProps> = ({ isPdfReport }) => {
  const { firmId, analysisId } =
    useParams<{ firmId: string; analysisId: string }>();
  const navigate = useNavigate();
  const { firmAnalysis, fetchFirmAnalysis } = useApp();

  const handleGetFirmAnalysis = useCallback(
    async (firmId: string, id: string) => {
      try {
        await fetchFirmAnalysis(parseInt(firmId, 10), parseInt(id, 10));
      } catch (error) {
        console.error(error);
      }
    },
    [fetchFirmAnalysis],
  );

  useEffect(() => {
    if (firmId && analysisId) {
      handleGetFirmAnalysis(firmId, analysisId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisId, firmId]);

  return (
    <>
      {!isPdfReport && (
        <Button
          type="link"
          icon={<LeftOutlined />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      )}
      <Title level={3}>Solvency</Title>
      {!isPdfReport && <Title level={4}>{firmAnalysis?.firm?.name}</Title>}
      <Row gutter={16} justify="center">
        <Col span={4}>
          <Card
            className="card-summary"
            title="Total Current Assets"
            bordered={false}
          >
            {firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
              "1_Donen_Varliklar"
            ]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                    "1_Donen_Varliklar"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card
            className="card-summary"
            title="Total Noncurrent Assets"
            bordered={false}
          >
            {firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
              "2_Duran_Varliklar"
            ]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                    "2_Duran_Varliklar"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card
            className="card-summary"
            title="Total Current Liabilities"
            bordered={false}
          >
            {firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
              "3_Kisa_Vadeli_Yukumlulukler"
            ]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                    "3_Kisa_Vadeli_Yukumlulukler"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card
            className="card-summary"
            title="Total Noncurrent Liabilities"
            bordered={false}
          >
            {firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
              "4_Uzun_Vadeli_Yukumlulukler"
            ]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                    "4_Uzun_Vadeli_Yukumlulukler"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card className="card-summary" title="Total Equity" bordered={false}>
            {firmAnalysis?.result?.["Rasyolar"]?.["B6_Ozsermaye_Buyukluk"]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Rasyolar"]?.[
                    "B6_Ozsermaye_Buyukluk"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="space-between" style={{ marginTop: 20 }}>
        <Col span={8}>
          <div className="white-box">
            <ColumnChart
              height={400}
              data={firmAnalysis?.result?.["Rasyolar"]?.[
                "F1_Finansal_Kaldirac_Oran"
              ]?.map((item: number, index: number, arr: number[]) => ({
                year: generateQuarterArray(
                  firmAnalysis?.result?.Donem,
                  arr.length,
                )[index],
                value: parseFloat(item.toFixed(3)),
              }))}
              xField="year"
              yField="value"
              title="Financial Leverage Ratio Over Years"
              yAxisDescription="Financial Leverage Ratio (%)"
              xAxisDescription="Year"
              suffix="%"
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <GaugeChart
              height={400}
              data={{
                target:
                  firmAnalysis?.result?.Skorlar?.Finansal_Yapi_Skor * 100 || 0,
                total: 1000,
                name: "score",
                thresholds: [333, 666, 1000],
              }}
              title="Solvency Score"
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <ColumnChart
              height={400}
              data={firmAnalysis?.result?.["Rasyolar"]?.[
                "F3_Finansman_Oran"
              ]?.map((item: number, index: number, arr: number[]) => ({
                year: generateQuarterArray(
                  firmAnalysis?.result?.Donem,
                  arr.length,
                )[index],
                value: parseFloat(item.toFixed(3)),
              }))}
              xField="year"
              yField="value"
              title="Financing Ratio Over Years"
              yAxisDescription="Financing Ratio"
              xAxisDescription="Year"
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <ColumnChart
              height={400}
              data={firmAnalysis?.result?.["Rasyolar"]?.[
                "F2_Ozkaynak_Oran"
              ]?.map((item: number, index: number, arr: number[]) => ({
                year: generateQuarterArray(
                  firmAnalysis?.result?.Donem,
                  arr.length,
                )[index],
                value: parseFloat(item.toFixed(3)),
              }))}
              xField="year"
              yField="value"
              title="Equity Ratio Over Years"
              yAxisDescription="Equity Ratio (%)"
              xAxisDescription="Year"
              suffix="%"
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <Row
              gutter={8}
              justify="center"
              align="middle"
              style={{ textAlign: "center", height: 400 }}
            >
              <Col span={12}>
                <Row justify="center">
                  <Title level={5}>Financial Leverage Ratio</Title>
                </Row>
                <Row justify="center">
                  <Progress
                    type="circle"
                    percent={Number(
                      (
                        firmAnalysis?.result?.Rasyolar
                          ?.F1_Finansal_Kaldirac_Oran?.[0] * 10
                      ).toFixed(1),
                    )}
                    format={(percent) => `${percent}%`}
                  />
                </Row>
              </Col>
              <Col span={12}>
                <Row justify="center">
                  <Title level={5}>Equity Ratio</Title>
                </Row>
                <Row justify="center">
                  <Progress
                    type="circle"
                    percent={Number(
                      (
                        firmAnalysis?.result?.Rasyolar?.F2_Ozkaynak_Oran?.[0] *
                        10
                      ).toFixed(1),
                    )}
                    format={(percent) => `${percent}%`}
                  />
                </Row>
              </Col>
              <Col span={12}>
                <Row justify="center">
                  <Title level={5}>Financing Ratio</Title>
                </Row>
                <Row justify="center">
                  <Progress
                    type="circle"
                    percent={Number(
                      (
                        firmAnalysis?.result?.Rasyolar?.F3_Finansman_Oran?.[0] *
                        10
                      ).toFixed(1),
                    )}
                    format={(percent) => `${percent}%`}
                  />
                </Row>
              </Col>
              <Col span={12}>
                <Row justify="center">
                  <Title level={5}>Capital Multiplier</Title>
                </Row>
                <Row justify="center">
                  <Progress
                    type="circle"
                    percent={Number(
                      (
                        firmAnalysis?.result?.Rasyolar
                          ?.F4_Sermaye_Carpani?.[0] * 10
                      ).toFixed(1),
                    )}
                    format={(percent) => `${percent}%`}
                  />
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <ColumnChart
              height={400}
              data={firmAnalysis?.result?.["Rasyolar"]?.[
                "F4_Sermaye_Carpani"
              ]?.map((item: number, index: number, arr: number[]) => ({
                year: generateQuarterArray(
                  firmAnalysis?.result?.Donem,
                  arr.length,
                )[index],
                value: parseFloat(item.toFixed(3)),
              }))}
              xField="year"
              yField="value"
              title="Capital Multiplier Over Years"
              yAxisDescription="Capital Multiplier (in millions)"
              xAxisDescription="Year"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Solvency;

import React from "react";
import { Column } from "@ant-design/plots";

interface GroupedColumnChartData {
  [key: string]: any; // Flexible to accommodate various data schemas
}

interface GroupedColumnChartProps {
  data: GroupedColumnChartData[];
  xField: string;
  yField: string;
  colorField: string;
  labelFormatter?: (data: any) => string; // Optional prop for custom label formatting
  title?: string;
  yAxisDescription?: string;
  xAxisDescription?: string;
  height?: number;
}

const GroupedColumnChart: React.FC<GroupedColumnChartProps> = ({
  data,
  xField,
  yField,
  colorField,
  labelFormatter,
  title,
  yAxisDescription,
  xAxisDescription,
  height,
}) => {
  const config = {
    data,
    xField,
    yField,
    colorField,
    group: true,
    label: {
      textBaseline: "bottom",
    },
    title: {
      title,
      align: "center",
    },
    axis: {
      y: { title: yAxisDescription },
      x: { title: xAxisDescription },
    },
    height,
    // axis: {
    //   y: {
    //     labelFormatter: (val: string) => `${parseFloat(val) * 100}%`, // Customize as needed
    //   },
    // },
  };

  return <Column {...config} />;
};

export default GroupedColumnChart;

import {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";
import {
  ILogin,
  IRegister,
  IAuthResponse,
} from "../containers/Authentication/types/authentication";
import { IFeedbackRequest } from "../containers/Feedback/types/feedback";
import { IFirm, IFirmRequest, ISector } from "../containers/Firms/types";
import {
  IFirmAnalysis,
  IFirmAnalysisRequest,
} from "../containers/FinAnalysis/types";
import { loginRequest } from "../services/requests/login";
import { registerRequest } from "../services/requests/register";
import { createFeedbackRequest } from "../services/requests/feedback";
import {
  fetchFirmsRequest,
  createFirmRequest,
  updateFirmRequest,
  deleteFirmRequest,
} from "../services/requests/firm";
import { fetchSectorsRequest } from "../services/requests/sector";
import {
  createFirmAnalysisRequest,
  fetchFirmAnalysesRequest,
  fetchFirmAnalysisRequest,
  deleteFirmAnalysisRequest,
} from "../services/requests/firmAnalysis";
import {
  ILLMAnalysis,
  ILLMAnalysisRequest,
} from "../containers/LLMAnalysis/types";
import { createLLMAnalysisRequest } from "../services/requests/llmAnalysis";

import { UserStorage, AuthUser } from "../services/UserStorage";

interface AppContextState {
  authUser: IAuthResponse | null;
  login: (payload: ILogin) => void;
  logout: () => void;
  register: (payload: IRegister) => void;
  sideMenuCollapsed: boolean;
  toggleSideMenuCollapsed: () => void;
  file: File | null;
  updateFile: (file: File | null) => void;
  createFeedback: (payload: IFeedbackRequest) => void;
  firms: IFirm[];
  fetchFirms: () => void;
  createFirm: (payload: IFirmRequest) => void;
  updateFirm: (id: number, payload: IFirmRequest) => void;
  deleteFirm: (id: number) => void;
  sectors: ISector[];
  fetchSectors: () => void;
  firmToBeUpdated: IFirm | null;
  setFirmToBeUpdated: (firm: IFirm | null) => void;
  firmAnalyses: IFirmAnalysis[];
  firmAnalysis: IFirmAnalysis | null;
  fetchFirmAnalyses: (id: number) => void;
  fetchFirmAnalysis: (firmId: number, id: number) => void;
  createFirmAnalysis: (payload: IFirmAnalysisRequest) => void;
  deleteFirmAnalysis: (id: number) => void;
  llmAnalysis: ILLMAnalysis | null;
  createLLMAnalysis: (payload: ILLMAnalysisRequest) => void;
  selectedFirm: IFirm | null;
  setSelectedFirm: (firm: IFirm | null) => void;
  isPdfGenerating: boolean;
  setIsPdfGenerating: (value: boolean) => void;
  loadingAuth: boolean;
  loadingFeedback: boolean;
  loadingFirms: boolean;
  loadingCreateFirm: boolean;
  loadingUpdateFirm: boolean;
  loadingDeleteFirm: boolean;
  loadingSectors: boolean;
  loadingFirmAnalyses: boolean;
  loadingFirmAnalysis: boolean;
  loadingCreateFirmAnalysis: boolean;
  loadingDeleteFirmAnalysis: boolean;
  loadingLLMAnalysis: boolean;
}

const defaultAppContext: AppContextState = {
  authUser: null,
  login: () => {},
  logout: () => {},
  register: () => {},
  sideMenuCollapsed: false,
  toggleSideMenuCollapsed: () => {},
  file: null,
  updateFile: () => {},
  createFeedback: () => {},
  firms: [],
  fetchFirms: () => {},
  createFirm: () => {},
  updateFirm: () => {},
  deleteFirm: () => {},
  sectors: [],
  fetchSectors: () => {},
  firmToBeUpdated: null,
  setFirmToBeUpdated: () => {},
  firmAnalyses: [],
  firmAnalysis: null,
  fetchFirmAnalyses: () => {},
  fetchFirmAnalysis: () => {},
  createFirmAnalysis: () => {},
  deleteFirmAnalysis: () => {},
  llmAnalysis: null,
  createLLMAnalysis: () => {},
  selectedFirm: null,
  setSelectedFirm: () => {},
  isPdfGenerating: false,
  setIsPdfGenerating: () => {},
  loadingAuth: false,
  loadingFeedback: false,
  loadingFirms: false,
  loadingCreateFirm: false,
  loadingUpdateFirm: false,
  loadingDeleteFirm: false,
  loadingSectors: false,
  loadingFirmAnalyses: false,
  loadingFirmAnalysis: false,
  loadingCreateFirmAnalysis: false,
  loadingDeleteFirmAnalysis: false,
  loadingLLMAnalysis: false,
};

const useLoadingState = (initialState: boolean) => {
  const [loading, setLoading] = useState(initialState);

  const updateLoading = useCallback((value: boolean) => {
    setLoading(value);
  }, []);

  return [loading, updateLoading] as const;
};

const useAppContext = (props: AppContextState): AppContextState => {
  const [authUser, setAuthUser] = useState(props.authUser);
  const [loadingAuth, updateLoadingAuth] = useLoadingState(props.loadingAuth);
  const [loadingFeedback, updateLoadingFeedback] = useLoadingState(
    props.loadingFeedback,
  );
  const [loadingFirms, updateLoadingFirms] = useLoadingState(
    props.loadingFirms,
  );
  const [loadingCreateFirm, updateLoadingCreateFirm] = useLoadingState(
    props.loadingCreateFirm,
  );
  const [loadingUpdateFirm, updateLoadingUpdateFirm] = useLoadingState(
    props.loadingUpdateFirm,
  );
  const [loadingDeleteFirm, updateLoadingDeleteFirm] = useLoadingState(
    props.loadingDeleteFirm,
  );
  const [loadingSectors, updateLoadingSectors] = useLoadingState(
    props.loadingSectors,
  );
  const [loadingLLMAnalysis, updateLoadingLLMAnalysis] = useLoadingState(
    props.loadingLLMAnalysis,
  );
  const [isPdfGenerating, setIsPdfGenerating] = useState(props.isPdfGenerating);
  const [sideMenuCollapsed, setSideMenuCollapsed] = useState(
    props.sideMenuCollapsed,
  );
  const [file, setFile] = useState(props.file);
  const [firms, setFirms] = useState(props.firms);
  const [sectors, setSectors] = useState(props.sectors);
  const [firmToBeUpdated, setFirmToBeUpdated] = useState(props.firmToBeUpdated);
  const [loadingFirmAnalyses, updateLoadingFirmAnalyses] = useLoadingState(
    props.loadingFirmAnalyses,
  );
  const [loadingFirmAnalysis, updateLoadingFirmAnalysis] = useLoadingState(
    props.loadingFirmAnalysis,
  );
  const [loadingCreateFirmAnalysis, updateLoadingCreateFirmAnalysis] =
    useLoadingState(props.loadingCreateFirmAnalysis);

  const [loadingDeleteFirmAnalysis, updateLoadingDeleteFirmAnalysis] =
    useLoadingState(props.loadingDeleteFirmAnalysis);
  const [firmAnalyses, setFirmAnalyses] = useState(props.firmAnalyses);
  const [firmAnalysis, setFirmAnalysis] = useState(props.firmAnalysis);
  const [selectedFirm, setSelectedFirm] = useState(props.selectedFirm);
  const [llmAnalysis, setLLMAnalysis] = useState(props.llmAnalysis);

  const updateAuthUser = useCallback(
    (authUser: AppContextState["authUser"]) => {
      setAuthUser(authUser);
    },
    [],
  );

  const updateFile = useCallback((file: AppContextState["file"]) => {
    setFile(file);
  }, []);

  const toggleSideMenuCollapsed = () => {
    setSideMenuCollapsed(!sideMenuCollapsed);
  };

  const login = async (payload: ILogin) => {
    updateLoadingAuth(true);
    try {
      const data: IAuthResponse = await loginRequest(payload);
      UserStorage.storeUser(data);
      updateAuthUser(data);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingAuth(false);
    }
  };

  const register = async (payload: IRegister) => {
    updateLoadingAuth(true);
    try {
      const data: IAuthResponse = await registerRequest(payload);
      UserStorage.storeUser(data);
      updateAuthUser(data);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingAuth(false);
    }
  };

  const logout = () => {
    UserStorage.clear();
    updateAuthUser(defaultAppContext.authUser);
  };

  useEffect(() => {
    if (UserStorage.isAuthenticated()) {
      const id = UserStorage.getUser(AuthUser.ID);
      const username = UserStorage.getUser(AuthUser.Username);
      const email = UserStorage.getUser(AuthUser.Email);
      const accessToken = UserStorage.getUser(AuthUser.AccessToken);
      const refreshToken = UserStorage.getUser(AuthUser.RefreshToken);
      if (id && username && email && accessToken) {
        updateAuthUser({
          access: accessToken,
          refresh: refreshToken || "",
          user: { pk: parseInt(id, 10), username, email },
        });
      }
    }
  }, [updateAuthUser]);

  const createFeedback = async (payload: IFeedbackRequest) => {
    updateLoadingFeedback(true);
    try {
      await createFeedbackRequest(payload);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingFeedback(false);
    }
  };

  const fetchFirms = async () => {
    updateLoadingFirms(true);
    try {
      const firms = await fetchFirmsRequest();
      setFirms(firms);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingFirms(false);
    }
  };

  const createFirm = async (payload: IFirmRequest) => {
    updateLoadingCreateFirm(true);
    try {
      const firm = await createFirmRequest(payload);
      setFirms([firm, ...firms]);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingCreateFirm(false);
    }
  };

  const updateFirm = async (id: number, payload: IFirmRequest) => {
    updateLoadingUpdateFirm(true);
    try {
      const firm = await updateFirmRequest(id, payload);
      setFirms(firms.map((f) => (f.id === id ? firm : f)));
    } catch (error) {
      throw error;
    } finally {
      updateLoadingUpdateFirm(false);
    }
  };

  const deleteFirm = async (id: number) => {
    updateLoadingDeleteFirm(true);
    try {
      await deleteFirmRequest(id);
      setFirms(firms.filter((f) => f.id !== id));
    } catch (error) {
      throw error;
    } finally {
      updateLoadingDeleteFirm(false);
    }
  };

  const fetchSectors = async () => {
    updateLoadingSectors(true);
    try {
      const sectors = await fetchSectorsRequest();
      setSectors(sectors);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingSectors(false);
    }
  };

  const fetchFirmAnalyses = async (id: number) => {
    updateLoadingFirmAnalyses(true);
    try {
      setSelectedFirm(firms.find((f) => f.id === id) || null);
      const analyses = await fetchFirmAnalysesRequest(id);
      setFirmAnalyses(analyses);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingFirmAnalyses(false);
    }
  };

  const fetchFirmAnalysis = async (firmId: number, id: number) => {
    updateLoadingFirmAnalysis(true);
    try {
      setSelectedFirm(firms.find((f) => f.id === firmId) || null);
      const analysis = await fetchFirmAnalysisRequest(id);
      setFirmAnalysis(analysis);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingFirmAnalysis(false);
    }
  };

  const createFirmAnalysis = async (payload: IFirmAnalysisRequest) => {
    updateLoadingCreateFirmAnalysis(true);
    try {
      const analysis = await createFirmAnalysisRequest(payload);
      setFirmAnalyses([analysis]);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingCreateFirmAnalysis(false);
    }
  };

  const deleteFirmAnalysis = async (id: number) => {
    updateLoadingDeleteFirmAnalysis(true);
    try {
      await deleteFirmAnalysisRequest(id);
      setFirmAnalyses(firmAnalyses.filter((f) => f.id !== id));
    } catch (error) {
      throw error;
    } finally {
      updateLoadingDeleteFirmAnalysis(false);
    }
  };

  const createLLMAnalysis = async (payload: ILLMAnalysisRequest) => {
    updateLoadingLLMAnalysis(true);
    try {
      const analysis = await createLLMAnalysisRequest(payload);
      setLLMAnalysis(analysis);
    } catch (error) {
      throw error;
    } finally {
      updateLoadingLLMAnalysis(false);
    }
  };

  return {
    login,
    logout,
    register,
    authUser,
    loadingAuth,
    sideMenuCollapsed,
    toggleSideMenuCollapsed,
    file,
    updateFile,
    createFeedback,
    loadingFeedback,
    createFirm,
    firms,
    fetchFirms,
    updateFirm,
    deleteFirm,
    sectors,
    fetchSectors,
    firmToBeUpdated,
    setFirmToBeUpdated,
    loadingFirms,
    loadingCreateFirm,
    loadingUpdateFirm,
    loadingDeleteFirm,
    loadingSectors,
    firmAnalyses,
    firmAnalysis,
    fetchFirmAnalyses,
    fetchFirmAnalysis,
    createFirmAnalysis,
    deleteFirmAnalysis,
    loadingFirmAnalyses,
    loadingFirmAnalysis,
    loadingCreateFirmAnalysis,
    loadingDeleteFirmAnalysis,
    selectedFirm,
    setSelectedFirm,
    isPdfGenerating,
    setIsPdfGenerating,
    llmAnalysis,
    createLLMAnalysis,
    loadingLLMAnalysis,
  };
};

const AppContext = createContext<AppContextState>(defaultAppContext);

export const useApp = (): AppContextState => {
  return useContext(AppContext);
};

export const AppContextConsumer = AppContext.Consumer;

export const AppContextProvider: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const contextValues = useAppContext(defaultAppContext);

  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
};

import React from "react";
import { Row, Col, Modal } from "antd";
import GroupedColumnChart from "../../../components/Charts/GroupedColumnChart";
import PieChart from "../../../components/Charts/PieChart";
import { useApp } from "../../../context/app.context";
import { formatCurrency } from "../../../utils/helpers";

interface SummaryReportModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const SummaryReportModal: React.FC<SummaryReportModalProps> = ({
  open,
  setOpen,
}) => {
  const { firmAnalyses } = useApp();

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      title={`Summary Report for ${firmAnalyses?.[0]?.firm?.name}`}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      width={700}
    >
      <Row gutter={32}>
        <Col span={12}>
          <GroupedColumnChart
            data={[
              {
                name: "Assets Total",
                year: firmAnalyses?.[0]?.result?.Donem,
                data: firmAnalyses?.[0]?.result?.Rasyolar?.B5_Aktif_Buyukluk?.[0]
                  ? `$${formatCurrency(
                      firmAnalyses?.[0]?.result?.Rasyolar?.B5_Aktif_Buyukluk?.reduce(
                        (acc: number, index: number) => {
                          return acc + index;
                        },
                        0,
                      ),
                    )}`
                  : "-",
              },
              {
                name: "Current Assets",
                year: firmAnalyses?.[0]?.result?.Donem,
                data: firmAnalyses?.[0]?.result?.["Finansal_THP_Alanlari"]?.[
                  "1_Donen_Varliklar"
                ]?.[0]
                  ? `$${formatCurrency(
                      firmAnalyses?.[0]?.result?.["Finansal_THP_Alanlari"]?.[
                        "1_Donen_Varliklar"
                      ]?.reduce((acc: number, index: number) => {
                        return acc + index;
                      }, 0),
                    )}`
                  : "-",
              },
            ]}
            xField="year"
            yField="data"
            colorField="name"
            title="Assets"
            yAxisDescription="Assets ($)"
            xAxisDescription="Year"
          />
        </Col>
        <Col span={12}>
          <PieChart
            data={firmAnalyses?.[0]?.result?.Summary ? Object.entries(firmAnalyses?.[0]?.result?.Summary)?.map(
              ([key, value]) => ({
                type: key,
                value,
              }),
            ) : []}
            angleField="value"
            colorField="type"
            title="Input Items"
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default SummaryReportModal;

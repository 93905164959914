import React from "react";

interface TextWithNewLinesProps {
  text: string;
}

const TextWithNewLines: React.FC<TextWithNewLinesProps> = ({ text }) => {
  return (
    <div>
      {text.split(/\n\n+/).map((paragraph, index) => (
        <p key={index} style={{ textAlign: "justify" }}>
          {paragraph.split("\n").map((line, lineIndex) => (
            <React.Fragment key={lineIndex}>
              {line}
              {lineIndex < paragraph.split("\n").length - 1 && <br />}
            </React.Fragment>
          ))}
        </p>
      ))}
    </div>
  );
};

export default TextWithNewLines;

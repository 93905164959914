import {
  IRegister,
  IAuthResponse,
} from "../../containers/Authentication/types/authentication";
import BackendClient from "../BackendClient";
import { AuthenticationUrl } from "../constants/urls";

export const registerRequest = async (
  payload: IRegister,
): Promise<IAuthResponse> => {
  try {
    const { data } = await BackendClient.post(
      AuthenticationUrl.Register,
      payload,
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

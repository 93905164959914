import React, { useState } from "react";
import { Typography, Form, Input, Button, Checkbox } from "antd";
import { LoadingOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import { useApp } from "../../context/app.context";
import "./index.css";

const { Title } = Typography;

const Authentication: React.FC = () => {
  const { login, register, loadingAuth } = useApp();
  const [isLogin, setIsLogin] = useState(true);

  const handleLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      await login({ email, password });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRegister = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      await register({
        email,
        username: email,
        password1: password,
        password2: password,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Form
        name="authentication"
        className="login-container"
        initialValues={{ remember: true }}
        onFinish={isLogin ? handleLogin : handleRegister}
      >
        <Title level={3}>{isLogin ? "Login" : "Register"}</Title>
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        {!isLogin ? null : (
          <Form.Item className="login-form-remember">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Button type="link" className="login-link-button">
              Forgot password
            </Button>
          </Form.Item>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            disabled={loadingAuth}
          >
            {loadingAuth && <LoadingOutlined />}{" "}
            {isLogin ? "Login" : "Register"}
          </Button>
          Or{" "}
          <Button
            type="link"
            className="login-link-button"
            onClick={() => setIsLogin(!isLogin)}
          >
            {isLogin ? "register now!" : "you already have an account?"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Authentication;

import React, { useEffect, useCallback, useRef, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Space,
  Table,
  Popconfirm,
} from "antd";
import type { InputRef, TableColumnsType, TableColumnType } from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import CreateFirmDrawer from "./CreateFirmDrawer";
import type { IFirm } from "./types";
import { useApp } from "../../context/app.context";
import { getNestedValue } from "../../utils/helpers";

const { Title } = Typography;

const Firms: React.FC = () => {
  const {
    firms,
    fetchFirms,
    deleteFirm,
    loadingFirms,
    loadingDeleteFirm,
    setFirmToBeUpdated,
  } = useApp();
  const searchInput = useRef<InputRef>(null);
  const [openCreateFirm, setOpenCreateFirm] = useState(false);

  const toggleDrawer = () => setOpenCreateFirm(!openCreateFirm);

  const getFirms = useCallback(async () => {
    try {
      await fetchFirms();
    } catch (error) {
      console.error(error);
    }
  }, [fetchFirms]);

  useEffect(() => {
    getFirms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateFirm = () => {
    setFirmToBeUpdated(null);
    toggleDrawer();
  };

  const handleUpdateFirm = (firm: IFirm) => {
    setFirmToBeUpdated(firm);
    toggleDrawer();
  };

  const handleDeleteFirm = async (id: number) => {
    try {
      await deleteFirm(id);
    } catch (error) {
      console.error(error);
    }
  };

  const getColumnSearchProps = (
    dataIndex: string | string[],
  ): TableColumnType<IFirm> => {
    const handleSearch = (
      selectedKeys: string[],
      confirm: () => void,
      dataIndex: string | string[],
    ) => {
      confirm();
    };

    const handleReset = (clearFilters: () => void) => {
      clearFilters();
    };

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${
              Array.isArray(dataIndex) ? dataIndex.join(" ") : dataIndex
            }`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        const nestedValue = getNestedValue(
          record,
          Array.isArray(dataIndex) ? dataIndex.join(".") : dataIndex,
        );
        return nestedValue
          ? nestedValue
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase())
          : "";
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) => text,
    };
  };

  const columns: TableColumnsType<IFirm> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Tax ID",
      dataIndex: "tax_id",
      key: "tax_id",
      ...getColumnSearchProps("tax_id"),
      sorter: (a, b) =>
        a.tax_id.toLowerCase().localeCompare(b.tax_id.toLowerCase()),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Contact Person",
      dataIndex: "contact_person",
      key: "contact_person",
      ...getColumnSearchProps("contact_person"),
      sorter: (a, b) =>
        a.contact_person
          .toLowerCase()
          .localeCompare(b.contact_person.toLowerCase()),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Partner",
      dataIndex: "partner",
      key: "partner",
      ...getColumnSearchProps("partner"),
      sorter: (a, b) =>
        a.partner.toLowerCase().localeCompare(b.partner.toLowerCase()),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Sector",
      dataIndex: ["sector", "name"],
      key: "sector",
      ...getColumnSearchProps(["sector", "name"]),
      sorter: (a, b) =>
        a.sector.name.toLowerCase().localeCompare(b.sector.name.toLowerCase()),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      ...getColumnSearchProps("year"),
      sorter: (a, b) => a.year - b.year,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      ...getColumnSearchProps("address"),
      sorter: (a, b) =>
        a.address.toLowerCase().localeCompare(b.address.toLowerCase()),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      ...getColumnSearchProps("created_at"),
      sorter: (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      sortDirections: ["descend", "ascend"],
      render: (created_at) => new Date(created_at).toLocaleDateString(),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleUpdateFirm(record)}
          />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDeleteFirm(record.id)}
            okButtonProps={{ loading: loadingDeleteFirm }}
          >
            <Button type="link" icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={3}>Firms</Title>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={handleCreateFirm}
            icon={<PlusOutlined />}
          >
            Create Firm
          </Button>
        </Col>
      </Row>
      <Table
        loading={loadingFirms}
        columns={columns}
        dataSource={firms}
        rowKey="id"
      />
      <CreateFirmDrawer open={openCreateFirm} onClose={toggleDrawer} />
    </>
  );
};

export default Firms;

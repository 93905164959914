import React, { useEffect, useCallback } from "react";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import type { IFirmRequest } from "../types";
import { useApp } from "../../../context/app.context";

interface CreateFirmDrawerProps {
  open: boolean;
  onClose: () => void;
}

const CreateFirmDrawer: React.FC<CreateFirmDrawerProps> = ({
  open,
  onClose,
}) => {
  const [form] = Form.useForm();
  const {
    createFirm,
    loadingCreateFirm,
    firmToBeUpdated,
    updateFirm,
    loadingUpdateFirm,
    sectors,
    fetchSectors,
    loadingSectors,
  } = useApp();

  useEffect(() => {
    form.resetFields();
  }, [open, form]);

  const getSectors = useCallback(async () => {
    try {
      await fetchSectors();
    } catch (error) {
      console.error(error);
    }
  }, [fetchSectors]);

  useEffect(() => {
    getSectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitFirm = async (values: IFirmRequest) => {
    const payload = {
      ...values,
      year: parseInt(dayjs(values.year)?.format("YYYY"), 10),
    };
    try {
      if (firmToBeUpdated) {
        await updateFirm(firmToBeUpdated.id, payload);
      } else {
        await createFirm(payload);
      }
      onClose();
      form.resetFields();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Drawer
      title={firmToBeUpdated ? "Update Firm" : "Create a New Firm"}
      width={720}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        name="create-firm"
        onFinish={submitFirm}
        initialValues={
          firmToBeUpdated
            ? {
                ...firmToBeUpdated,
                sector: firmToBeUpdated.sector.id,
                year: dayjs(firmToBeUpdated.year.toString()),
              }
            : {
                year: dayjs(),
              }
        }
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Firm Name is required!" }]}
            >
              <Input placeholder="Please enter Firm Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tax_id"
              label="# Tax ID"
              rules={[{ required: true, message: "Tax ID is required!" }]}
            >
              <Input placeholder="Please enter Tax ID" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="contact_person"
              label="Contact Person"
              rules={[
                { required: true, message: "Contact Person is required!" },
              ]}
            >
              <Input placeholder="Please enter Contact Person" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="partner"
              label="Partner"
              rules={[{ required: true, message: "Partner is required!" }]}
            >
              <Input placeholder="Please enter Partner #" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="sector"
              label="Sector"
              rules={[{ required: true, message: "Sector is required!" }]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Please choose the sector"
                optionFilterProp="children"
                filterOption={(
                  input: string,
                  option?: { label: string; value: number },
                ) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase(),
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={sectors.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
                loading={loadingSectors}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="year"
              label="Year"
              rules={[{ required: true, message: "Year is required!" }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Please choose the year"
                picker="year"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: "Address is required!" }]}
            >
              <Input.TextArea placeholder="Please enter the address" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loadingUpdateFirm || loadingCreateFirm}
            >
              {(loadingUpdateFirm || loadingCreateFirm) && <LoadingOutlined />}{" "}
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default CreateFirmDrawer;

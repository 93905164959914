import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Select,
  Input,
  Space,
  Popconfirm,
  Table,
  Spin,
} from "antd";
import type { InputRef, TableColumnsType, TableColumnType } from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  DeleteOutlined,
  BarChartOutlined,
  PieChartOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import CreateFirmAnalysisDrawer from "./CreateFirmAnalysisDrawer";
import SummaryReportModal from "./SummaryReportModal";
import GeneratePdf from "../GeneratePdf";
import Cover from "../GeneratePdf/Cover";
import { useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../context/app.context";
import type { IFirmAnalysis } from "./types";
import { Url } from "../../constants/urls";
import { getNestedValue } from "../../utils/helpers";

const { Title } = Typography;

const FinAnalysis: React.FC = () => {
  const {
    selectedFirm,
    setSelectedFirm,
    firms,
    fetchFirms,
    loadingFirms,
    firmAnalyses,
    fetchFirmAnalyses,
    deleteFirmAnalysis,
    loadingFirmAnalyses,
    loadingDeleteFirmAnalysis,
    isPdfGenerating,
    loadingLLMAnalysis,
  } = useApp();

  const { firmId } = useParams<{ firmId: string }>();
  const navigate = useNavigate();

  const searchInput = useRef<InputRef>(null);
  const [openCreateFirmAnalysis, setOpenCreateFirmAnalysis] = useState(false);
  const [isSummaryReportModalOpen, setIsSummaryReportModalOpen] =
    useState(false);

  const toggleDrawer = () => setOpenCreateFirmAnalysis(!openCreateFirmAnalysis);

  const getFirms = useCallback(async () => {
    try {
      await fetchFirms();
    } catch (error) {
      console.error(error);
    }
  }, [fetchFirms]);

  useEffect(() => {
    getFirms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetFirmAnalyses = useCallback(
    async (id: number) => {
      try {
        await fetchFirmAnalyses(id);
      } catch (error) {
        console.error(error);
      }
    },
    [fetchFirmAnalyses],
  );

  useEffect(() => {
    if (firms.length > 0 && firmId) {
      handleGetFirmAnalyses(parseInt(firmId, 10));
    } else {
      setSelectedFirm(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firmId, firms]);

  const handleCreateFirmAnalysis = () => {
    toggleDrawer();
  };

  const handleDeleteFirmAnalysis = async (id: number) => {
    try {
      await deleteFirmAnalysis(id);
    } catch (error) {
      console.error(error);
    }
  };

  const getColumnSearchProps = (
    dataIndex: string | string[],
  ): TableColumnType<IFirmAnalysis> => {
    const handleSearch = (
      selectedKeys: string[],
      confirm: () => void,
      dataIndex: string | string[],
    ) => {
      confirm();
    };

    const handleReset = (clearFilters: () => void) => {
      clearFilters();
    };

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Search ${
              Array.isArray(dataIndex) ? dataIndex.join(" ") : dataIndex
            }`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() =>
                handleSearch(selectedKeys as string[], confirm, dataIndex)
              }
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        const nestedValue = getNestedValue(
          record,
          Array.isArray(dataIndex) ? dataIndex.join(".") : dataIndex,
        );
        return nestedValue
          ? nestedValue
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase())
          : "";
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) => text,
    };
  };

  const columns: TableColumnsType<IFirmAnalysis> = [
    {
      title: "File",
      dataIndex: "file_path",
      key: "file_path",
      render: (file_path) => (
        <a href={file_path} target="_blank" rel="noopener noreferrer">
          <Button type="link" style={{ color: "green" }}>
            <FileExcelOutlined /> Statements
          </Button>
        </a>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      ...getColumnSearchProps("updated_at"),
      sorter: (a, b) =>
        new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
      sortDirections: ["descend", "ascend"],
      render: (updated_at) => new Date(updated_at).toLocaleDateString(),
    },
    {
      title: "Summary",
      key: "summary",
      render: (_, record) => (
        <Button type="link" onClick={() => setIsSummaryReportModalOpen(true)}>
          <PieChartOutlined /> Outline
        </Button>
      ),
    },
    {
      title: "Analysis",
      key: "result",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() =>
            navigate(
              Url.FinAnalysisResultsRoute.replace(
                ":firmId",
                firmId ?? "",
              ).replace(":analysisId", record.id.toString()),
            )
          }
        >
          <BarChartOutlined /> Financial Results
        </Button>
      ),
    },
    {
      title: "Report",
      key: "report",
      render: (_, record) => (
        <GeneratePdf firmId={Number(firmId)} analysisId={record.id}>
          <div>
            <Cover />
          </div>
        </GeneratePdf>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDeleteFirmAnalysis(record.id)}
            okButtonProps={{ loading: loadingDeleteFirmAnalysis }}
          >
            <Button type="link" icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Spin
      tip={`Report ${loadingLLMAnalysis ? "Generating.." : "Downloading.."}`}
      size="large"
      spinning={isPdfGenerating}
    >
      <Title level={3}>FinAnalysis</Title>
      <Title level={5}>Select a Firm</Title>
      <Row justify="space-between" align="middle">
        <Col>
          <Select
            showSearch
            allowClear
            style={{ width: 300 }}
            placeholder="Select a Firm"
            optionFilterProp="children"
            filterOption={(
              input: string,
              option?: { label: string; value: number },
            ) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
            options={firms.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            onChange={(firmId) =>
              navigate(
                Url.FinAnalysisRoute.replace(
                  ":firmId?",
                  firmId.toString() ?? "",
                ),
              )
            }
            loading={loadingFirms}
            disabled={loadingFirms}
            value={selectedFirm?.id}
          />
        </Col>
        {selectedFirm && (
          <Col>
            <Button
              type="primary"
              onClick={handleCreateFirmAnalysis}
              icon={<PlusOutlined />}
            >
              New Analysis
            </Button>
          </Col>
        )}
      </Row>
      {selectedFirm && (
        <div style={{ marginTop: 15 }}>
          <Table
            loading={loadingFirmAnalyses}
            columns={columns}
            dataSource={firmAnalyses}
            rowKey="id"
          />
          <CreateFirmAnalysisDrawer
            open={openCreateFirmAnalysis}
            onClose={toggleDrawer}
          />
          <SummaryReportModal
            open={isSummaryReportModalOpen}
            setOpen={setIsSummaryReportModalOpen}
          />
        </div>
      )}
    </Spin>
  );
};

export default FinAnalysis;

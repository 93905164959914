import React from "react";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import Papa from "papaparse";

const { Dragger } = Upload;

interface FileUploaderProps {
  accept: string;
  description?: string;
  setFile: (file: File | null) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  accept,
  description = "a file",
  setFile,
}) => {
  // Function to truncate file name
  const truncateFileName = (name: string, maxLength: number = 28) => {
    if (name.length <= maxLength) return name;

    const extension = name.substring(name.lastIndexOf(".")); // Extracts the extension including the dot
    const baseName = name.substring(0, name.lastIndexOf(".")); // Extracts the base name without the extension

    // Ensure the truncated name includes the extension and fits within the maxLength
    return `${baseName.substring(
      0,
      maxLength - extension.length - 3,
    )}...${extension}`;
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  return (
    <Dragger
      style={{ padding: "0 20px" }}
      accept={accept}
      maxCount={1}
      onRemove={handleRemoveFile}
      beforeUpload={(file) => {
        setFile(file);
        const reader = new FileReader();

        reader.onload = ({ target }) => {
          const data = target?.result?.toString();
          if (data) {
            Papa.parse<string[]>(data, {
              complete: ({ data }) => {
                console.log("Columns", data);
              },
            });
          }
        };
        reader.readAsBinaryString(file);
        return false; // Prevent upload
      }}
      itemRender={(originNode, file, fileList, actions) => {
        // Modify the child with a title prop to truncate its file name
        const newChildren = React.Children.map(
          originNode.props.children,
          (child) => {
            // Using TypeScript assertions for better type inference
            const element = child as React.ReactElement<any>;

            if (element && element.props?.title) {
              return React.cloneElement(element, {
                ...element.props,
                title: truncateFileName(element.props.title),
                children:
                  element.props.children &&
                  React.Children.map(element.props.children, (subChild) => {
                    // Nested string check within children
                    if (typeof subChild === "string") {
                      return truncateFileName(subChild);
                    }
                    return subChild;
                  }),
              });
            }
            return element;
          },
        );

        return React.cloneElement(originNode, {
          ...originNode.props,
          children: newChildren,
        });
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag {description}</p>
      <p className="ant-upload-text">to this area to upload</p>
      <p className="ant-upload-hint">
        CSV, XLSX, and XLS
        <br />
        formats are supported.
      </p>
    </Dragger>
  );
};

export default FileUploader;

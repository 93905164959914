import React, { useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Card, Typography, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import GaugeChart from "../../../../components/Charts/GaugeChart";
import ColumnChart from "../../../../components/Charts/ColumnChart";
import { useApp } from "../../../../context/app.context";
import {
  formatCurrency,
  generateQuarterArray,
} from "../../../../utils/helpers";
import "../index.css";

const { Title } = Typography;

interface ValuationProps {
  isPdfReport?: boolean;
}

const Valuation: React.FC<ValuationProps> = ({ isPdfReport }) => {
  const { firmId, analysisId } =
    useParams<{ firmId: string; analysisId: string }>();
  const navigate = useNavigate();
  const { firmAnalysis, fetchFirmAnalysis } = useApp();

  const handleGetFirmAnalysis = useCallback(
    async (firmId: string, id: string) => {
      try {
        await fetchFirmAnalysis(parseInt(firmId, 10), parseInt(id, 10));
      } catch (error) {
        console.error(error);
      }
    },
    [fetchFirmAnalysis],
  );

  useEffect(() => {
    if (firmId && analysisId) {
      handleGetFirmAnalysis(firmId, analysisId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisId, firmId]);

  return (
    <>
      {!isPdfReport && (
        <Button
          type="link"
          icon={<LeftOutlined />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      )}
      <Title level={3}>Valuation</Title>
      {!isPdfReport && <Title level={4}>{firmAnalysis?.firm?.name}</Title>}
      <Row gutter={16} justify="center">
        <Col span={3}>
          <Card
            className="card-summary"
            title="Total Current Assets"
            bordered={false}
          >
            {firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
              "1_Donen_Varliklar"
            ]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                    "1_Donen_Varliklar"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card
            className="card-summary"
            title="Total Noncurrent Assets"
            bordered={false}
          >
            {firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
              "2_Duran_Varliklar"
            ]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                    "2_Duran_Varliklar"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card
            className="card-summary"
            title="Total Current Liabilities"
            bordered={false}
          >
            {firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
              "3_Kisa_Vadeli_Yukumlulukler"
            ]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                    "3_Kisa_Vadeli_Yukumlulukler"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card
            className="card-summary"
            title="Total Noncurrent Liabilities"
            bordered={false}
          >
            {firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
              "4_Uzun_Vadeli_Yukumlulukler"
            ]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                    "4_Uzun_Vadeli_Yukumlulukler"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={3}>
          <Card className="card-summary" title="Total Equity" bordered={false}>
            {firmAnalysis?.result?.["Rasyolar"]?.["B6_Ozsermaye_Buyukluk"]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Rasyolar"]?.[
                    "B6_Ozsermaye_Buyukluk"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={3}>
          <Card className="card-summary" title="Revenue" bordered={false}>
            {firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
              "60_Satis_Gelirleri"
            ]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                    "60_Satis_Gelirleri"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={3}>
          <Card className="card-summary" title="EBITDA" bordered={false}>
            {firmAnalysis?.result?.["Rasyolar"]?.["L5_Favok"]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Rasyolar"]?.["L5_Favok"]?.reduce(
                    (acc: number, index: number) => {
                      return acc + index;
                    },
                    0,
                  ),
                )}`
              : "-"}
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="space-between" style={{ marginTop: 20 }}>
        <Col span={8}>
          <div className="white-box">
            <ColumnChart
              height={400}
              data={firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                "69_DONEM_KARI__ZARARI_"
              ]?.map((item: number, index: number, arr: number[]) => ({
                year: generateQuarterArray(
                  firmAnalysis?.result?.Donem,
                  arr.length,
                )[index],
                value: item,
              }))}
              xField="year"
              yField="value"
              title="Period Profit Over Years"
              yAxisDescription="Period Profit ($)"
              xAxisDescription="Year"
              suffix="$"
              formatter={formatCurrency}
              hideLabel
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <GaugeChart
              height={400}
              data={{
                target: firmAnalysis?.result?.Skorlar?.Buyukluk_Skor * 100 || 0,
                total: 1000,
                name: "score",
                thresholds: [333, 666, 1000],
              }}
              title="Valuation Score"
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <ColumnChart
              height={400}
              data={firmAnalysis?.result?.["Rasyolar"]?.[
                "B5_Aktif_Buyukluk"
              ]?.map((item: number, index: number, arr: number[]) => ({
                year: generateQuarterArray(
                  firmAnalysis?.result?.Donem,
                  arr.length,
                )[index],
                value: item,
              }))}
              xField="year"
              yField="value"
              title="Total Asset Size Over Years"
              yAxisDescription="Total Asset Size ($)"
              xAxisDescription="Year"
              suffix="$"
              formatter={formatCurrency}
              hideLabel
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <ColumnChart
              height={400}
              data={firmAnalysis?.result?.["Rasyolar"]?.[
                "B3_Toplam_Borc_Buyuklugu"
              ]?.map((item: number, index: number, arr: number[]) => ({
                year: generateQuarterArray(
                  firmAnalysis?.result?.Donem,
                  arr.length,
                )[index],
                value: item,
              }))}
              xField="year"
              yField="value"
              title="Total Debt Size Over Years"
              yAxisDescription="Total Debt Size ($)"
              xAxisDescription="Year"
              suffix="$"
              formatter={formatCurrency}
              hideLabel
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <Row
              gutter={8}
              align="middle"
              justify="center"
              style={{ textAlign: "center", paddingBottom: 20 }}
            >
              <Col span={12}>
                <Row justify="center">
                  <Title level={5}>Period Profit</Title>
                </Row>
                <Row justify="center">
                  {formatCurrency(
                    firmAnalysis?.result?.Rasyolar?.[
                      "69_DONEM_KARI__ZARARI_"
                    ]?.[0],
                  )}
                </Row>
              </Col>
              <Col span={12}>
                <Row justify="center">
                  <Title level={5}>Total Debt Size</Title>
                </Row>
                <Row justify="center">
                  $
                  {formatCurrency(
                    firmAnalysis?.result?.Rasyolar?.[
                      "B3_Toplam_Borc_Buyuklugu"
                    ]?.[0],
                  )}
                </Row>
              </Col>
              <Col span={12}>
                <Row justify="center">
                  <Title level={5}>Total Asset Size</Title>
                </Row>
                <Row justify="center">
                  $
                  {formatCurrency(
                    firmAnalysis?.result?.Rasyolar?.["B5_Aktif_Buyukluk"]?.[0],
                  )}
                </Row>
              </Col>
              <Col span={12}>
                <Row justify="center">
                  <Title level={5}>Total Equity Size</Title>
                </Row>
                <Row justify="center">
                  $
                  {formatCurrency(
                    firmAnalysis?.result?.Rasyolar?.[
                      "B6_Ozsermaye_Buyukluk"
                    ]?.[0],
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <ColumnChart
              height={400}
              data={firmAnalysis?.result?.["Rasyolar"]?.[
                "B6_Ozsermaye_Buyukluk"
              ]?.map((item: number, index: number, arr: number[]) => ({
                year: generateQuarterArray(
                  firmAnalysis?.result?.Donem,
                  arr.length,
                )[index],
                value: item,
              }))}
              xField="year"
              yField="value"
              title="Total Equity Size Over Years"
              yAxisDescription="Total Equity Size ($)"
              xAxisDescription="Year"
              suffix="$"
              formatter={formatCurrency}
              hideLabel
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Valuation;

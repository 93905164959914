import React from "react";
import { Pie } from "@ant-design/plots";

interface PieChartData {
  [key: string]: any; // Flexible to accommodate various data schemas
}

interface PieChartProps {
  data: PieChartData[];
  angleField: string;
  colorField: string;
  title?: string;
  yAxisDescription?: string;
  xAxisDescription?: string;
  height?: number;
}

const PieChart: React.FC<PieChartProps> = ({
  data,
  angleField,
  colorField,
  title,
  yAxisDescription,
  xAxisDescription,
  height,
}) => {
  const config = {
    data,
    angleField,
    colorField,
    label: {
      text: angleField,
      style: {
        fontWeight: "bold",
      },
    },
    legend: {
      color: {
        title: false,
        rowPadding: 5,
      },
    },
    title: {
      title,
      align: "center",
    },
    axis: {
      y: { title: yAxisDescription },
      x: { title: xAxisDescription },
    },
    height,
  };
  return <Pie {...config} />;
};

export default PieChart;

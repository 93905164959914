import React from "react";
import { Column } from "@ant-design/plots";

interface ColumnChartData {
  [key: string]: any; // Flexible to accommodate various data schemas
}

interface ColumnChartProps {
  data: ColumnChartData[];
  xField: string;
  yField: string;
  suffix?: string;
  title?: string;
  yAxisDescription?: string;
  xAxisDescription?: string;
  height?: number;
  formatter?: (text: any) => string;
  hideLabel?: boolean;
}

const ColumnChart: React.FC<ColumnChartProps> = ({
  data,
  xField,
  yField,
  suffix = "",
  title,
  yAxisDescription,
  xAxisDescription,
  height,
  formatter = (text) => text,
  hideLabel = false,
}) => {
  const customFormatter = (text: string) =>
    suffix ? `${formatter(text)}${suffix}` : formatter(text);

  const config = {
    data,
    xField,
    yField,
    label: {
      textBaseline: "bottom",
      formatter: (text: any, item: any, index: number) => customFormatter(text),
      ...(hideLabel && {
        transform: [
          {
            type: "overflowHide",
          },
        ],
      }),
    },
    axis: {
      y: {
        title: yAxisDescription,
        labelFormatter: (val: string) => customFormatter(val),
      },
      x: {
        title: xAxisDescription,
      },
    },
    title: {
      title,
      align: "center",
    },
    height,
  };

  return <Column {...config} />;
};

export default ColumnChart;

import { message } from "antd";
import { ISector } from "../../containers/Firms/types";
import BackendClient from "../BackendClient";
import { SectorsUrl } from "../constants/urls";

export const fetchSectorsRequest = async (): Promise<ISector[]> => {
  try {
    const { data } = await BackendClient.get(SectorsUrl.GetSectors);
    return data;
  } catch (error) {
    console.error(error);
    message.error("Failed to fetch sectors. Please try again later.");
    throw error;
  }
};

export enum Url {
  BaseRoute = "/",
  ProductsRoute = "/products/",
  FinAnalysisRoute = "/products/finanalysis/:firmId?",
  FinAnalysisResultsRoute = "/products/finanalysis/:firmId/results/:analysisId",
  FinAnalysisResultsLiquidityRoute = "/products/finanalysis/:firmId/results/:analysisId/liquidity",
  FinAnalysisResultsProfitabilityRoute = "/products/finanalysis/:firmId/results/:analysisId/profitability",
  FinAnalysisResultsSolvencyRoute = "/products/finanalysis/:firmId/results/:analysisId/solvency",
  FinAnalysisResultsActivityRoute = "/products/finanalysis/:firmId/results/:analysisId/activity",
  FinAnalysisResultsValuationRoute = "/products/finanalysis/:firmId/results/:analysisId/valuation",
  FirmsRoute = "/firms/",
}

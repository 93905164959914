import React, { useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Card, Progress, Typography, Button } from "antd";
import GaugeChart from "../../../components/Charts/GaugeChart";
import ColumnChart from "../../../components/Charts/ColumnChart";
import LineChart from "../../../components/Charts/LineChart";
import { useApp } from "../../../context/app.context";
import { Url } from "../../../constants/urls";
import { formatCurrency, generateQuarterArray } from "../../../utils/helpers";
import "./index.css";

const { Title } = Typography;

const FinAnalysisResults: React.FC = () => {
  const { firmId, analysisId } =
    useParams<{ firmId: string; analysisId: string }>();
  const navigate = useNavigate();

  const { firmAnalysis, fetchFirmAnalysis } = useApp();

  const handleGetFirmAnalysis = useCallback(
    async (firmId: string, id: string) => {
      try {
        await fetchFirmAnalysis(parseInt(firmId, 10), parseInt(id, 10));
      } catch (error) {
        console.error(error);
      }
    },
    [fetchFirmAnalysis],
  );

  useEffect(() => {
    if (firmId && analysisId) {
      handleGetFirmAnalysis(firmId, analysisId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisId, firmId]);

  return (
    <>
      <Title level={3}>Financial Analysis Summary</Title>
      <Title level={4}>{firmAnalysis?.firm?.name}</Title>
      <Row gutter={16} justify="center">
        <Col span={4}>
          <Card className="card-summary" title="Total Revenue" bordered={false}>
            {firmAnalysis?.result?.Rasyolar?.B4_Toplam_Alacak_Buyuklugu?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.Rasyolar?.B4_Toplam_Alacak_Buyuklugu?.reduce(
                    (acc: number, index: number) => {
                      return acc + index;
                    },
                    0,
                  ),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card className="card-summary" title="Net Income" bordered={false}>
            {firmAnalysis?.result?.Rasyolar?.B1_Donem_Kari?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.Rasyolar?.B1_Donem_Kari?.reduce(
                    (acc: number, index: number) => {
                      return acc + index;
                    },
                    0,
                  ),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card className="card-summary" title="Total Assets" bordered={false}>
            {firmAnalysis?.result?.Rasyolar?.B5_Aktif_Buyukluk?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.Rasyolar?.B5_Aktif_Buyukluk?.reduce(
                    (acc: number, index: number) => {
                      return acc + index;
                    },
                    0,
                  ),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card
            className="card-summary"
            title="Total Liability"
            bordered={false}
          >
            {firmAnalysis?.result?.Rasyolar?.B3_Toplam_Borc_Buyuklugu?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.Rasyolar?.B3_Toplam_Borc_Buyuklugu?.reduce(
                    (acc: number, index: number) => {
                      return acc + index;
                    },
                    0,
                  ),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card className="card-summary" title="Total Equity" bordered={false}>
            {firmAnalysis?.result?.Rasyolar?.B6_Ozsermaye_Buyukluk?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.Rasyolar?.B6_Ozsermaye_Buyukluk?.reduce(
                    (acc: number, index: number) => {
                      return acc + index;
                    },
                    0,
                  ),
                )}`
              : "-"}
          </Card>
        </Col>
      </Row>
      <Row gutter={16} justify="center">
        <Col>
          <Row justify="center">
            <Title level={5}>Activity</Title>
          </Row>
          <Row justify="center">
            <Progress
              type="circle"
              percent={
                firmAnalysis?.result?.Skorlar?.Varlik_Kullanim_Skor * 10 || 0
              }
              format={(percent) => `${percent}%`}
            />
          </Row>
          <Row justify="center">
            <Button
              type="primary"
              size="small"
              style={{ marginTop: 5 }}
              onClick={() =>
                navigate(
                  Url.FinAnalysisResultsActivityRoute.replace(
                    ":firmId",
                    firmId ?? "",
                  ).replace(":analysisId", analysisId ?? ""),
                )
              }
            >
              Details
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="center">
            <Title level={5}>Liquidity</Title>
          </Row>
          <Row justify="center">
            <Progress
              type="circle"
              percent={firmAnalysis?.result?.Skorlar?.Likidite_Skor * 10 || 0}
              format={(percent) => `${percent}%`}
            />
          </Row>
          <Row justify="center">
            <Button
              type="primary"
              size="small"
              style={{ marginTop: 5 }}
              onClick={() =>
                navigate(
                  Url.FinAnalysisResultsLiquidityRoute.replace(
                    ":firmId",
                    firmId ?? "",
                  ).replace(":analysisId", analysisId ?? ""),
                )
              }
            >
              Details
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="center">
            <Title level={5}>Solvency</Title>
          </Row>
          <Row justify="center">
            <Progress
              type="circle"
              percent={
                firmAnalysis?.result?.Skorlar?.Finansal_Yapi_Skor * 10 || 0
              }
              format={(percent) => `${percent}%`}
            />
          </Row>
          <Row justify="center">
            <Button
              type="primary"
              size="small"
              style={{ marginTop: 5 }}
              onClick={() =>
                navigate(
                  Url.FinAnalysisResultsSolvencyRoute.replace(
                    ":firmId",
                    firmId ?? "",
                  ).replace(":analysisId", analysisId ?? ""),
                )
              }
            >
              Details
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="center">
            <Title level={5}>Profitability</Title>
          </Row>
          <Row justify="center">
            <Progress
              type="circle"
              percent={firmAnalysis?.result?.Skorlar?.Karlilik * 10 || 0}
              format={(percent) => `${percent}%`}
            />
          </Row>
          <Row justify="center">
            <Button
              type="primary"
              size="small"
              style={{ marginTop: 5 }}
              onClick={() =>
                navigate(
                  Url.FinAnalysisResultsProfitabilityRoute.replace(
                    ":firmId",
                    firmId ?? "",
                  ).replace(":analysisId", analysisId ?? ""),
                )
              }
            >
              Details
            </Button>
          </Row>
        </Col>
        <Col>
          <Row justify="center">
            <Title level={5}>Valuation</Title>
          </Row>
          <Row justify="center">
            <Progress
              type="circle"
              percent={firmAnalysis?.result?.Skorlar?.Buyukluk_Skor * 10 || 0}
              format={(percent) => `${percent}%`}
            />
          </Row>
          <Row justify="center">
            <Button
              type="primary"
              size="small"
              style={{ marginTop: 5 }}
              onClick={() =>
                navigate(
                  Url.FinAnalysisResultsValuationRoute.replace(
                    ":firmId",
                    firmId ?? "",
                  ).replace(":analysisId", analysisId ?? ""),
                )
              }
            >
              Details
            </Button>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="space-between" style={{ marginTop: 20 }}>
        <Col span={8}>
          <div className="white-box">
            <LineChart
              height={400}
              data={
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "B4_Toplam_Alacak_Buyuklugu"
                ]?.map((item: number, index: number, arr: number[]) => ({
                  year: generateQuarterArray(
                    firmAnalysis?.result?.Donem,
                    arr.length,
                  )[index],
                  value: parseFloat(item.toFixed(3)),
                })) || []
              }
              xField="year"
              yField="value"
              title="Revenue Growth"
              yAxisDescription="Revenue Growth"
              formatter={formatCurrency}
              suffix="$"
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <GaugeChart
              height={400}
              data={{
                target:
                  ((firmAnalysis?.result?.Skorlar?.Varlik_Kullanim_Skor || 0) +
                    (firmAnalysis?.result?.Skorlar?.Likidite_Skor || 0) +
                    (firmAnalysis?.result?.Skorlar?.Finansal_Yapi_Skor || 0) +
                    (firmAnalysis?.result?.Skorlar?.Karlilik || 0) +
                    (firmAnalysis?.result?.Skorlar?.Buyukluk_Skor || 0)) *
                  20,
                total: 1000,
                name: "score",
                thresholds: [333, 666, 1000],
              }}
              title="Quality Score"
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <ColumnChart
              height={400}
              data={
                firmAnalysis?.result?.["Rasyolar"]?.["L1_Cari_Oran"]?.map(
                  (item: number, index: number, arr: number[]) => ({
                    year: generateQuarterArray(
                      firmAnalysis?.result?.Donem,
                      arr.length,
                    )[index],
                    value: parseFloat(item.toFixed(3)),
                  }),
                ) || []
              }
              xField="year"
              yField="value"
              title="Current Ratio Over Years"
              yAxisDescription="Current Ratio"
              xAxisDescription="Year"
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="white-box">
            <ColumnChart
              height={400}
              data={
                firmAnalysis?.result?.["Rasyolar"]?.["K2_Sermaye_Karlilik"]
                  ?.filter(Boolean)
                  ?.map((item: number, index: number, arr: number[]) => ({
                    year: generateQuarterArray(
                      firmAnalysis?.result?.Donem,
                      arr.length,
                    )[index],
                    value: parseFloat(item.toFixed(3)),
                  })) || []
              }
              xField="year"
              yField="value"
              title="Net Profit Margin Over Years"
              yAxisDescription="Net Profit Margin (%)"
              xAxisDescription="Year"
              suffix="%"
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="white-box">
            <LineChart
              height={400}
              data={
                firmAnalysis?.result?.["Rasyolar"]?.["F3_Finansman_Oran"]
                  ?.filter(Boolean)
                  ?.map((item: number, index: number, arr: number[]) => ({
                    year: generateQuarterArray(
                      firmAnalysis?.result?.Donem,
                      arr.length,
                    )[index],
                    value: parseFloat(item.toFixed(3)),
                  })) || []
              }
              xField="year"
              yField="value"
              title="Debt-to-Equity Ratio Over Years"
              yAxisDescription="Debt-to-Equity Ratio"
              xAxisDescription="Year"
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="white-box">
            <LineChart
              height={400}
              data={
                firmAnalysis?.result?.["Rasyolar"]?.["K1_Ozkaynak_Karlilik"]
                  ?.filter(Boolean)
                  ?.map((item: number, index: number, arr: number[]) => ({
                    year: generateQuarterArray(
                      firmAnalysis?.result?.Donem,
                      arr.length,
                    )[index],
                    value: parseFloat(item.toFixed(3)),
                  })) || []
              }
              xField="year"
              yField="value"
              title="Return on Equity Over Years"
              yAxisDescription="Return on Equity (ROE) %"
              xAxisDescription="Year"
              suffix="%"
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="white-box">
            <ColumnChart
              height={400}
              data={
                firmAnalysis?.result?.["Rasyolar"]?.["B6_Ozsermaye_Buyukluk"]
                  ?.filter(Boolean)
                  ?.map((item: number, index: number, arr: number[]) => ({
                    year: generateQuarterArray(
                      firmAnalysis?.result?.Donem,
                      arr.length,
                    )[index],
                    value: parseFloat(item.toFixed(3)),
                  })) || []
              }
              xField="year"
              yField="value"
              title="Total Equity Over Years"
              yAxisDescription="Total Equity"
              xAxisDescription="Year"
              suffix="$"
              formatter={formatCurrency}
              hideLabel
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FinAnalysisResults;

import React, { useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Card, Progress, Typography, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import GaugeChart from "../../../../components/Charts/GaugeChart";
import ColumnChart from "../../../../components/Charts/ColumnChart";
import { useApp } from "../../../../context/app.context";
import {
  formatCurrency,
  generateQuarterArray,
} from "../../../../utils/helpers";
import "../index.css";

const { Title } = Typography;

interface ProfitabilityProps {
  isPdfReport?: boolean;
}

const Profitability: React.FC<ProfitabilityProps> = ({ isPdfReport }) => {
  const { firmId, analysisId } =
    useParams<{ firmId: string; analysisId: string }>();
  const navigate = useNavigate();
  const { firmAnalysis, fetchFirmAnalysis } = useApp();

  const handleGetFirmAnalysis = useCallback(
    async (firmId: string, id: string) => {
      try {
        await fetchFirmAnalysis(parseInt(firmId, 10), parseInt(id, 10));
      } catch (error) {
        console.error(error);
      }
    },
    [fetchFirmAnalysis],
  );

  useEffect(() => {
    if (firmId && analysisId) {
      handleGetFirmAnalysis(firmId, analysisId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisId, firmId]);

  return (
    <>
      {!isPdfReport && (
        <Button
          type="link"
          icon={<LeftOutlined />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      )}
      <Title level={3}>Profitability</Title>
      {!isPdfReport && <Title level={4}>{firmAnalysis?.firm?.name}</Title>}
      <Row gutter={16} justify="center">
        <Col span={4}>
          <Card
            className="card-summary"
            title="Total Current Assets"
            bordered={false}
          >
            {firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
              "1_Donen_Varliklar"
            ]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                    "1_Donen_Varliklar"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card
            className="card-summary"
            title="Total Noncurrent Assets"
            bordered={false}
          >
            {firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
              "2_Duran_Varliklar"
            ]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                    "2_Duran_Varliklar"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card
            className="card-summary"
            title="Total Current Liabilities"
            bordered={false}
          >
            {firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
              "3_Kisa_Vadeli_Yukumlulukler"
            ]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                    "3_Kisa_Vadeli_Yukumlulukler"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card className="card-summary" title="Total Equity" bordered={false}>
            {firmAnalysis?.result?.["Rasyolar"]?.["B6_Ozsermaye_Buyukluk"]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Rasyolar"]?.[
                    "B6_Ozsermaye_Buyukluk"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
        <Col span={4}>
          <Card className="card-summary" title="Gross Profit" bordered={false}>
            {firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
              "69_DONEM_KARI__ZARARI_"
            ]?.[0]
              ? `$${formatCurrency(
                  firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                    "69_DONEM_KARI__ZARARI_"
                  ]?.reduce((acc: number, index: number) => {
                    return acc + index;
                  }, 0),
                )}`
              : "-"}
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="space-between" style={{ marginTop: 20 }}>
        <Col span={8}>
          <div className="white-box">
            <ColumnChart
              height={400}
              data={firmAnalysis?.result?.["Rasyolar"]?.[
                "K1_Ozkaynak_Karlilik"
              ]?.map((item: number, index: number, arr: number[]) => ({
                year: generateQuarterArray(
                  firmAnalysis?.result?.Donem,
                  arr.length,
                )[index],
                value: parseFloat(item.toFixed(3)),
              }))}
              xField="year"
              yField="value"
              title="Equity Profitability Over Years"
              yAxisDescription="Equity Profitability (%)"
              xAxisDescription="Year"
              suffix="%"
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <GaugeChart
              height={400}
              data={{
                target: firmAnalysis?.result?.Skorlar?.Karlilik * 100 || 0,
                total: 1000,
                name: "score",
                thresholds: [333, 666, 1000],
              }}
              title="Profitability Score"
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <ColumnChart
              height={400}
              data={firmAnalysis?.result?.["Rasyolar"]?.[
                "K3_Aktif_Karlilik"
              ]?.map((item: number, index: number, arr: number[]) => ({
                year: generateQuarterArray(
                  firmAnalysis?.result?.Donem,
                  arr.length,
                )[index],
                value: parseFloat(item.toFixed(3)),
              }))}
              xField="year"
              yField="value"
              title="Asset Profitability Over Years"
              yAxisDescription="Asset Profitability"
              xAxisDescription="Year"
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="white-box">
            <ColumnChart
              height={400}
              data={firmAnalysis?.result?.["Rasyolar"]?.[
                "K5_Calisma_Sermayesi_Karlilik"
              ]?.map((item: number, index: number, arr: number[]) => ({
                year: generateQuarterArray(
                  firmAnalysis?.result?.Donem,
                  arr.length,
                )[index],
                value: parseFloat(item.toFixed(3)),
              }))}
              xField="year"
              yField="value"
              title="Working Capital Profitability Over Years"
              yAxisDescription="Working Capital Profitability (in millions)"
              xAxisDescription="Year"
            />
          </div>
        </Col>
        <Col span={16}>
          <div className="white-box">
            <Row
              gutter={64}
              justify="center"
              align="middle"
              style={{ textAlign: "center", paddingBottom: 20 }}
            >
              <Col>
                <Row justify="center">
                  <Title level={5}>Equity Profitability</Title>
                </Row>
                <Row justify="center">
                  <Progress
                    type="circle"
                    percent={Number(
                      (
                        firmAnalysis?.result?.Rasyolar
                          ?.K1_Ozkaynak_Karlilik?.[0] * 10
                      ).toFixed(1),
                    )}
                    format={(percent) => `${percent}%`}
                  />
                </Row>
              </Col>
              <Col>
                <Row justify="center">
                  <Title level={5}>Asset Profitability</Title>
                </Row>
                <Row justify="center">
                  <Progress
                    type="circle"
                    percent={Number(
                      (
                        firmAnalysis?.result?.Rasyolar?.K3_Aktif_Karlilik?.[0] *
                        10
                      ).toFixed(1),
                    )}
                    format={(percent) => `${percent}%`}
                  />
                </Row>
              </Col>
              <Col>
                <Row justify="center">
                  <Title level={5}>Working Capital Profitability</Title>
                </Row>
                <Row justify="center">
                  <Progress
                    type="circle"
                    percent={Number(
                      (
                        firmAnalysis?.result?.Rasyolar
                          ?.K5_Calisma_Sermayesi_Karlilik?.[0] * 10
                      ).toFixed(1),
                    )}
                    format={(percent) => `${percent}%`}
                  />
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Profitability;

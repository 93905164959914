import React from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Avatar,
  Progress,
  Descriptions,
  type DescriptionsProps,
} from "antd";
import { MailOutlined, AppstoreOutlined } from "@ant-design/icons";
import GaugeChart from "../../../components/Charts/GaugeChart";
import LineChart from "../../../components/Charts/LineChart";
import TextWithNewLines from "../../../components/TextWithNewLines";
import { useApp } from "../../../context/app.context";
import { formatCurrency, generateQuarterArray } from "../../../utils/helpers";

const { Title, Text } = Typography;

const Cover: React.FC = () => {
  const { selectedFirm, firmAnalysis, llmAnalysis } = useApp();

  const renderPdfChart = ({
    title,
    value,
    chartData,
    suffix,
    formatter,
  }: {
    title: string;
    value: string | number;
    chartData: number[];
    suffix?: string;
    formatter?: (text: any) => string;
  }) => (
    <div className="white-box border-box">
      <Card className="card-pdf-summary" title={title} bordered={false}>
        {value}
      </Card>
      <LineChart
        height={180}
        marginTop={-50}
        data={
          chartData
            ?.filter(Boolean)
            ?.map((item: number, index: number, arr: number[]) => ({
              year: generateQuarterArray(
                firmAnalysis?.result?.Donem,
                arr.length,
              )[index],
              value: item,
            })) || []
        }
        xField="year"
        yField="value"
        suffix={suffix}
        formatter={formatter}
      />
    </div>
  );

  const firmData: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Industry / Sector",
      children: selectedFirm?.sector?.name,
    },
    {
      key: "2",
      label: "Tax ID",
      children: selectedFirm?.tax_id,
    },
    {
      key: "3",
      label: "Contact Person",
      children: selectedFirm?.contact_person,
    },
    {
      key: "4",
      label: "Partner",
      children: selectedFirm?.partner,
    },
    {
      key: "5",
      label: "Address",
      children: selectedFirm?.address,
    },
    {
      key: "6",
      label: "Date of Analysis",
      children: firmAnalysis
        ? new Date(firmAnalysis.created_at).toLocaleDateString()
        : "-",
    },
    {
      key: "7",
      label: "Reporting Years",
      children: firmAnalysis
        ? generateQuarterArray(
            firmAnalysis.result.Donem,
            firmAnalysis.result["Rasyolar"]["K1_Ozkaynak_Karlilik"].filter(
              Boolean,
            ).length,
          ).join(", ")
        : "",
    },
  ];

  const data = [
    {
      icon: <AppstoreOutlined />,
      title: "Profitability",
      color: "#fa8c16",
      score: firmAnalysis?.result?.Skorlar?.Karlilik * 10 || 0,
      charts: [
        {
          title: "Equity Profitability",
          value: parseFloat(
            firmAnalysis?.result?.["Rasyolar"]?.[
              "K1_Ozkaynak_Karlilik"
            ]?.[0]?.toFixed(3) || 0,
          ),
          chartData:
            firmAnalysis?.result?.["Rasyolar"]?.["K1_Ozkaynak_Karlilik"] || [],
          suffix: "%",
        },
        {
          title: "Asset Profitability",
          value: parseFloat(
            firmAnalysis?.result?.["Rasyolar"]?.[
              "K3_Aktif_Karlilik"
            ]?.[0]?.toFixed(3) || 0,
          ),
          chartData:
            firmAnalysis?.result?.["Rasyolar"]?.["K3_Aktif_Karlilik"] || [],
          suffix: "%",
        },
        {
          title: "Working Capital Profitability",
          value: parseFloat(
            firmAnalysis?.result?.["Rasyolar"]?.[
              "K5_Calisma_Sermayesi_Karlilik"
            ]?.[0]?.toFixed(3) || 0,
          ),
          chartData:
            firmAnalysis?.result?.["Rasyolar"]?.[
              "K5_Calisma_Sermayesi_Karlilik"
            ] || [],
          suffix: "%",
        },
      ],
    },
    {
      icon: <AppstoreOutlined />,
      title: "Solvency",
      color: "#722ed1",
      score: firmAnalysis?.result?.Skorlar?.Finansal_Yapi_Skor * 10 || 0,
      charts: [
        {
          title: "Financial Leverage Ratio",
          value: parseFloat(
            firmAnalysis?.result?.["Rasyolar"]?.[
              "F1_Finansal_Kaldirac_Oran"
            ]?.[0]?.toFixed(3) || 0,
          ),
          chartData:
            firmAnalysis?.result?.["Rasyolar"]?.["F1_Finansal_Kaldirac_Oran"] ||
            [],
          suffix: "%",
        },
        {
          title: "Equity Ratio",
          value: parseFloat(
            firmAnalysis?.result?.["Rasyolar"]?.[
              "F2_Ozkaynak_Oran"
            ]?.[0]?.toFixed(3) || 0,
          ),
          chartData:
            firmAnalysis?.result?.["Rasyolar"]?.["F2_Ozkaynak_Oran"] || [],
          suffix: "%",
        },
        {
          title: "Financing Ratio",
          value: parseFloat(
            firmAnalysis?.result?.["Rasyolar"]?.[
              "F3_Finansman_Oran"
            ]?.[0]?.toFixed(3) || 0,
          ),
          chartData:
            firmAnalysis?.result?.["Rasyolar"]?.["F3_Finansman_Oran"] || [],
          suffix: "%",
        },
      ],
    },
    {
      icon: <AppstoreOutlined />,
      title: "Liquidity",
      color: "#1890ff",
      score: firmAnalysis?.result?.Skorlar?.Likidite_Skor * 10 || 0,
      charts: [
        {
          title: "Current Rate",
          value: parseFloat(
            firmAnalysis?.result?.["Rasyolar"]?.["L1_Cari_Oran"]?.[0]?.toFixed(
              3,
            ) || 0,
          ),
          chartData: firmAnalysis?.result?.["Rasyolar"]?.["L1_Cari_Oran"] || [],
          suffix: "%",
        },
        {
          title: "Acid Ratio",
          value: parseFloat(
            firmAnalysis?.result?.["Rasyolar"]?.["L2_Asit_Oran"]?.[0]?.toFixed(
              3,
            ) || 0,
          ),
          chartData: firmAnalysis?.result?.["Rasyolar"]?.["L2_Asit_Oran"] || [],
          suffix: "%",
        },
        {
          title: "Cash Rate",
          value: parseFloat(
            firmAnalysis?.result?.["Rasyolar"]?.["L3_Nakit_Oran"]?.[0]?.toFixed(
              3,
            ),
          ),
          chartData:
            firmAnalysis?.result?.["Rasyolar"]?.["L3_Nakit_Oran"] || [],
        },
      ],
    },
    {
      icon: <AppstoreOutlined />,
      title: "Activity",
      color: "#ff4d4f",
      score: firmAnalysis?.result?.Skorlar?.Varlik_Kullanim_Skor * 10 || 0,
      charts: [
        {
          title: "Inventory Turnover Speed",
          value: parseFloat(
            firmAnalysis?.result?.["Rasyolar"]?.[
              "V1_Stok_Devir_Hizi"
            ]?.[0]?.toFixed(3) || 0,
          ),
          chartData:
            firmAnalysis?.result?.["Rasyolar"]?.["V1_Stok_Devir_Hizi"] || [],
          suffix: "%",
        },
        {
          title: "Receivable Turnover Speed",
          value: parseFloat(
            firmAnalysis?.result?.["Rasyolar"]?.[
              "V3_Alacak_Devir_Hizi"
            ]?.[0]?.toFixed(3) || 0,
          ),
          chartData:
            firmAnalysis?.result?.["Rasyolar"]?.["V3_Alacak_Devir_Hizi"] || [],
          suffix: "%",
        },
        {
          title: "Asset Turnover Speed",
          value: parseFloat(
            firmAnalysis?.result?.["Rasyolar"]?.[
              "V8_Aktif_Devir_Hizi"
            ]?.[0]?.toFixed(3),
          ),
          chartData:
            firmAnalysis?.result?.["Rasyolar"]?.["V8_Aktif_Devir_Hizi"] || [],
          suffix: "%",
        },
      ],
    },
    {
      icon: <AppstoreOutlined />,
      title: "Valuation",
      color: "#13c2c2",
      score: firmAnalysis?.result?.Skorlar?.Buyukluk_Skor * 10 || 0,
      charts: [
        {
          title: "Total Debt Size",
          value: `$${formatCurrency(
            firmAnalysis?.result?.Rasyolar?.["B3_Toplam_Borc_Buyuklugu"]?.[0] ||
              0,
          )}`,
          chartData:
            firmAnalysis?.result?.["Rasyolar"]?.["B3_Toplam_Borc_Buyuklugu"] ||
            [],
          suffix: "$",
          formatter: formatCurrency,
        },
        {
          title: "Total Asset Size",
          value: `$${formatCurrency(
            firmAnalysis?.result?.Rasyolar?.["B5_Aktif_Buyukluk"]?.[0] || 0,
          )}`,
          chartData:
            firmAnalysis?.result?.["Rasyolar"]?.["B5_Aktif_Buyukluk"] || [],
          suffix: "$",
          formatter: formatCurrency,
        },
        {
          title: "Total Equity Size",
          value: `$${formatCurrency(
            firmAnalysis?.result?.Rasyolar?.["B6_Ozsermaye_Buyukluk"]?.[0] || 0,
          )}`,
          chartData:
            firmAnalysis?.result?.["Rasyolar"]?.["B6_Ozsermaye_Buyukluk"] || [],
          suffix: "$",
          formatter: formatCurrency,
        },
      ],
    },
  ];

  return (
    <Card
      style={{ width: "100%", borderRadius: "8px" }}
      cover={
        <div
          style={{
            background: `url('/pdf-cover.png') no-repeat center center`,
            backgroundSize: "cover",
            height: "200px",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <div style={{ padding: "16px 32px", color: "white" }}>
            <Title level={1} style={{ color: "white" }}>
              FinCraft
            </Title>
            <Title level={3} style={{ color: "white" }}>
              FINANCIAL REPORT
            </Title>
          </div>
        </div>
      }
    >
      {/* Page 1 */}

      <div style={{ height: 2100 }}>
        <Row gutter={16}>
          <Col span={14}>
            <div style={{ maxHeight: 620, overflow: "hidden" }}>
              <Row gutter={16}>
                <Col span={3}>
                  <Avatar size={64} icon={<MailOutlined />} />
                </Col>
                <Col span={21}>
                  <Descriptions
                    size="small"
                    title={selectedFirm?.name}
                    items={firmData}
                  />
                </Col>
              </Row>
              <Title level={3}>Executive Summary</Title>
              <div>
                <TextWithNewLines
                  text={
                    llmAnalysis?.executive_summary ||
                    "No executive summary available."
                  }
                />
              </div>
            </div>
            <GaugeChart
              height={350}
              data={{
                target:
                  ((firmAnalysis?.result?.Skorlar?.Varlik_Kullanim_Skor || 0) +
                    (firmAnalysis?.result?.Skorlar?.Likidite_Skor || 0) +
                    (firmAnalysis?.result?.Skorlar?.Finansal_Yapi_Skor || 0) +
                    (firmAnalysis?.result?.Skorlar?.Karlilik || 0) +
                    (firmAnalysis?.result?.Skorlar?.Buyukluk_Skor || 0)) *
                  20,
                total: 1000,
                name: "score",
                thresholds: [333, 666, 1000],
              }}
              title="Financial Quality Score"
            />
          </Col>
          <Col span={10}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                {renderPdfChart({
                  title: "Total Revenue",
                  value: firmAnalysis?.result?.Rasyolar
                    ?.B4_Toplam_Alacak_Buyuklugu?.[0]
                    ? `$${formatCurrency(
                        firmAnalysis?.result?.Rasyolar?.B4_Toplam_Alacak_Buyuklugu?.reduce(
                          (acc: number, index: number) => {
                            return acc + index;
                          },
                          0,
                        ),
                      )}`
                    : "-",
                  chartData:
                    firmAnalysis?.result?.["Rasyolar"]?.[
                      "B4_Toplam_Alacak_Buyuklugu"
                    ] || [],
                  suffix: "$",
                  formatter: formatCurrency,
                })}
              </Col>
              <Col span={12}>
                {renderPdfChart({
                  title: "EBITDA",
                  value: firmAnalysis?.result?.["Rasyolar"]?.["L5_Favok"]?.[0]
                    ? `$${formatCurrency(
                        firmAnalysis?.result?.["Rasyolar"]?.[
                          "L5_Favok"
                        ]?.reduce((acc: number, index: number) => {
                          return acc + index;
                        }, 0),
                      )}`
                    : "-",
                  chartData:
                    firmAnalysis?.result?.["Rasyolar"]?.["L5_Favok"] || [],
                  suffix: "$",
                  formatter: formatCurrency,
                })}
              </Col>
              <Col span={12}>
                {renderPdfChart({
                  title: "Total Assets",
                  value: firmAnalysis?.result?.Rasyolar?.B5_Aktif_Buyukluk?.[0]
                    ? `$${formatCurrency(
                        firmAnalysis?.result?.Rasyolar?.B5_Aktif_Buyukluk?.reduce(
                          (acc: number, index: number) => {
                            return acc + index;
                          },
                          0,
                        ),
                      )}`
                    : "-",
                  chartData:
                    firmAnalysis?.result?.["Rasyolar"]?.["B5_Aktif_Buyukluk"] ||
                    [],
                  suffix: "$",
                  formatter: formatCurrency,
                })}
              </Col>
              <Col span={12}>
                {renderPdfChart({
                  title: "Total Liability",
                  value: firmAnalysis?.result?.Rasyolar
                    ?.B3_Toplam_Borc_Buyuklugu?.[0]
                    ? `$${formatCurrency(
                        firmAnalysis?.result?.Rasyolar?.B3_Toplam_Borc_Buyuklugu?.reduce(
                          (acc: number, index: number) => {
                            return acc + index;
                          },
                          0,
                        ),
                      )}`
                    : "-",
                  chartData:
                    firmAnalysis?.result?.["Rasyolar"]?.[
                      "B3_Toplam_Borc_Buyuklugu"
                    ] || [],
                  suffix: "$",
                  formatter: formatCurrency,
                })}
              </Col>
              <Col span={12}>
                {renderPdfChart({
                  title: "Working Capital Profitability",
                  value: parseFloat(
                    firmAnalysis?.result?.Rasyolar?.K5_Calisma_Sermayesi_Karlilik?.[0]?.toFixed(
                      3,
                    ),
                  ),
                  chartData:
                    firmAnalysis?.result?.["Rasyolar"]?.[
                      "K5_Calisma_Sermayesi_Karlilik"
                    ] || [],
                })}
              </Col>
              <Col span={12}>
                {renderPdfChart({
                  title: "Debt / Equity",
                  value: parseFloat(
                    firmAnalysis?.result?.Rasyolar?.F3_Finansman_Oran?.[0]?.toFixed(
                      3,
                    ),
                  ),
                  chartData:
                    firmAnalysis?.result?.["Rasyolar"]?.["F3_Finansman_Oran"] ||
                    [],
                })}
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ marginTop: -80 }}>
          {data.map((item, index) => (
            <Row
              key={index}
              style={{ padding: "4px 0", borderBottom: "1px solid #f0f0f0" }}
              align="middle"
              gutter={32}
            >
              <Col span={1}>{item.icon}</Col>
              <Col span={3}>
                <Text strong>{item.title}</Text>
              </Col>
              <Col span={4}>
                <Progress
                  type="circle"
                  percent={item.score}
                  strokeColor={item.color}
                  format={(percent) => `${percent}%`}
                />
              </Col>
              <Col span={16}>
                <Row gutter={16}>
                  {item.charts.map((chart, index) => (
                    <Col key={index} span={8}>
                      {renderPdfChart(chart)}
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          ))}
        </div>
      </div>

      {/* Page 2 */}

      <div style={{ height: 2300 }}>
        <Row gutter={16} align="middle">
          <Col span={8}>
            <GaugeChart
              height={400}
              data={{
                target: firmAnalysis?.result?.Skorlar?.Karlilik * 100 || 0,
                total: 1000,
                name: "score",
                thresholds: [333, 666, 1000],
              }}
              title="Profitability Score"
            />
          </Col>
          <Col span={16}>
            <TextWithNewLines
              text={
                llmAnalysis?.profitability || "No profitability analysis found."
              }
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            {renderPdfChart({
              title: "Gross Profit",
              value: firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                "69_DONEM_KARI__ZARARI_"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                      "69_DONEM_KARI__ZARARI_"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "69_DONEM_KARI__ZARARI_"
                ] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Equity",
              value: firmAnalysis?.result?.["Rasyolar"]?.[
                "B6_Ozsermaye_Buyukluk"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Rasyolar"]?.[
                      "B6_Ozsermaye_Buyukluk"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["B6_Ozsermaye_Buyukluk"] ||
                [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6} />
          <Col span={6} />
          <Col span={6}>
            {renderPdfChart({
              title: "Net Profit Margin",
              value: parseFloat(
                firmAnalysis?.result?.Rasyolar?.K2_Sermaye_Karlilik?.[0]?.toFixed(
                  3,
                ),
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["K2_Sermaye_Karlilik"] ||
                [],
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Equity Profitability",
              value: parseFloat(
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "K1_Ozkaynak_Karlilik"
                ]?.[0]?.toFixed(3) || 0,
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["K1_Ozkaynak_Karlilik"] ||
                [],
              suffix: "%",
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Asset Profitability",
              value: parseFloat(
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "K3_Aktif_Karlilik"
                ]?.[0]?.toFixed(3) || 0,
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["K3_Aktif_Karlilik"] || [],
              suffix: "%",
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Working Capital Profitability",
              value: parseFloat(
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "K5_Calisma_Sermayesi_Karlilik"
                ]?.[0]?.toFixed(3) || 0,
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "K5_Calisma_Sermayesi_Karlilik"
                ] || [],
              suffix: "%",
            })}
          </Col>
        </Row>
        <Row style={{ marginTop: 100 }} gutter={16} align="middle">
          <Col span={8}>
            <GaugeChart
              height={400}
              data={{
                target:
                  firmAnalysis?.result?.Skorlar?.Finansal_Yapi_Skor * 100 || 0,
                total: 1000,
                name: "score",
                thresholds: [333, 666, 1000],
              }}
              title="Solvency Score"
            />
          </Col>
          <Col span={16}>
            <TextWithNewLines
              text={llmAnalysis?.solvency || "No solvency analysis found."}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            {renderPdfChart({
              title: "Total Liability",
              value: `$${formatCurrency(
                (firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "3_Kisa_Vadeli_Yukumlulukler"
                ]?.[0]
                  ? parseFloat(
                      firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                        "3_Kisa_Vadeli_Yukumlulukler"
                      ]?.reduce((acc: number, index: number) => {
                        return acc + index;
                      }, 0),
                    )
                  : 0) +
                  (firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                    "4_Uzun_Vadeli_Yukumlulukler"
                  ]?.[0]
                    ? parseFloat(
                        firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                          "4_Uzun_Vadeli_Yukumlulukler"
                        ]?.reduce((acc: number, index: number) => {
                          return acc + index;
                        }, 0),
                      )
                    : 0),
              )}`,
              chartData:
                firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "3_Kisa_Vadeli_Yukumlulukler"
                ]?.map(
                  (num: number, index: number) =>
                    num +
                    firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                      "4_Uzun_Vadeli_Yukumlulukler"
                    ]?.[index],
                ) || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Current Liability",
              value: firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                "3_Kisa_Vadeli_Yukumlulukler"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                      "3_Kisa_Vadeli_Yukumlulukler"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "3_Kisa_Vadeli_Yukumlulukler"
                ] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Noncurrent Liability",
              value: firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                "4_Uzun_Vadeli_Yukumlulukler"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                      "4_Uzun_Vadeli_Yukumlulukler"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "4_Uzun_Vadeli_Yukumlulukler"
                ] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Total Equity",
              value: firmAnalysis?.result?.["Rasyolar"]?.[
                "B6_Ozsermaye_Buyukluk"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Rasyolar"]?.[
                      "B6_Ozsermaye_Buyukluk"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["B6_Ozsermaye_Buyukluk"] ||
                [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Financial Leverage Ratio",
              value: parseFloat(
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "F1_Finansal_Kaldirac_Oran"
                ]?.[0]?.toFixed(3) || 0,
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "F1_Finansal_Kaldirac_Oran"
                ] || [],
              suffix: "%",
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Equity Ratio",
              value: parseFloat(
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "F2_Ozkaynak_Oran"
                ]?.[0]?.toFixed(3) || 0,
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["F2_Ozkaynak_Oran"] || [],
              suffix: "%",
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Financing Ratio",
              value: parseFloat(
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "F3_Finansman_Oran"
                ]?.[0]?.toFixed(3) || 0,
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["F3_Finansman_Oran"] || [],
              suffix: "%",
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Capital Multiplier",
              value: parseFloat(
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "F4_Sermaye_Carpani"
                ]?.[0]?.toFixed(3) || 0,
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["F4_Sermaye_Carpani"] ||
                [],
              suffix: "%",
            })}
          </Col>
        </Row>
      </div>

      {/* Page 3 */}

      <div style={{ height: 2300 }}>
        <Row gutter={16} align="middle">
          <Col span={8}>
            <GaugeChart
              height={400}
              data={{
                target: firmAnalysis?.result?.Skorlar?.Likidite_Skor * 100 || 0,
                total: 1000,
                name: "score",
                thresholds: [333, 666, 1000],
              }}
              title="Liquidity Score"
            />
          </Col>
          <Col span={16}>
            <TextWithNewLines
              text={llmAnalysis?.liquidity || "No liquidity analysis found."}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            {renderPdfChart({
              title: "Current Assets",
              value: firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                "1_Donen_Varliklar"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                      "1_Donen_Varliklar"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "1_Donen_Varliklar"
                ] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Inventories",
              value: firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                "15_Stoklar"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                      "15_Stoklar"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "15_Stoklar"
                ] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Cash and Cash Equivalent",
              value: firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                "10_Nakit_ve_Nakit_Benzerleri"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                      "10_Nakit_ve_Nakit_Benzerleri"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "10_Nakit_ve_Nakit_Benzerleri"
                ] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Current Liability",
              value: firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                "3_Kisa_Vadeli_Yukumlulukler"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                      "3_Kisa_Vadeli_Yukumlulukler"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "3_Kisa_Vadeli_Yukumlulukler"
                ] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Current Rate",
              value: parseFloat(
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "L1_Cari_Oran"
                ]?.[0]?.toFixed(3) || 0,
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["L1_Cari_Oran"] || [],
              suffix: "%",
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Acid Ratio",
              value: parseFloat(
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "L2_Asit_Oran"
                ]?.[0]?.toFixed(3) || 0,
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["L2_Asit_Oran"] || [],
              suffix: "%",
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Cash Rate",
              value: parseFloat(
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "L3_Nakit_Oran"
                ]?.[0]?.toFixed(3) || 0,
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["L3_Nakit_Oran"] || [],
              suffix: "%",
            })}
          </Col>
        </Row>
        <Row style={{ marginTop: 100 }} gutter={16} align="middle">
          <Col span={8}>
            <GaugeChart
              height={400}
              data={{
                target:
                  firmAnalysis?.result?.Skorlar?.Varlik_Kullanim_Skor * 100 ||
                  0,
                total: 1000,
                name: "score",
                thresholds: [333, 666, 1000],
              }}
              title="Activity Score"
            />
          </Col>
          <Col span={16}>
            <TextWithNewLines
              text={llmAnalysis?.activity || "No activity analysis found."}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            {renderPdfChart({
              title: "Inventories",
              value: firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                "15_Stoklar"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                      "15_Stoklar"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "15_Stoklar"
                ] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Inventories",
              value: firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                "12_Ticari_Alacaklar"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                      "12_Ticari_Alacaklar"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "12_Ticari_Alacaklar"
                ] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Total Assets",
              value: firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                "1_Donen_Varliklar"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                      "1_Donen_Varliklar"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "1_Donen_Varliklar"
                ] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6} />
          <Col span={6}>
            {renderPdfChart({
              title: "Inventory Turnover Speed",
              value: parseFloat(
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "V1_Stok_Devir_Hizi"
                ]?.[0]?.toFixed(3) || 0,
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["V1_Stok_Devir_Hizi"] ||
                [],
              suffix: "%",
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Receivable Turnover Speed",
              value: parseFloat(
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "V3_Alacak_Devir_Hizi"
                ]?.[0]?.toFixed(3) || 0,
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["V3_Alacak_Devir_Hizi"] ||
                [],
              suffix: "%",
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Asset Turnover Speed",
              value: parseFloat(
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "V8_Aktif_Devir_Hizi"
                ]?.[0]?.toFixed(3) || 0,
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["V8_Aktif_Devir_Hizi"] ||
                [],
              suffix: "%",
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Working Capital Turnover Speed",
              value: parseFloat(
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "V11_Calisma_Sermayesi_Devir_Hizi"
                ]?.[0]?.toFixed(3) || 0,
              ),
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "V11_Calisma_Sermayesi_Devir_Hizi"
                ] || [],
              suffix: "%",
            })}
          </Col>
        </Row>
      </div>

      {/* Page 4 */}

      <div style={{ height: 2000 }}>
        <Row gutter={16} align="middle">
          <Col span={8}>
            <GaugeChart
              height={400}
              data={{
                target: firmAnalysis?.result?.Skorlar?.Buyukluk_Skor * 100 || 0,
                total: 1000,
                name: "score",
                thresholds: [333, 666, 1000],
              }}
              title="Valuation Score"
            />
          </Col>
          <Col span={16}>
            <TextWithNewLines
              text={llmAnalysis?.valuation || "No valuation analysis found."}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            {renderPdfChart({
              title: "Total Assets",
              value: firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                "1_Donen_Varliklar"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                      "1_Donen_Varliklar"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "1_Donen_Varliklar"
                ] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Total Liability",
              value: firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                "3_Kisa_Vadeli_Yukumlulukler"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                      "3_Kisa_Vadeli_Yukumlulukler"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "3_Kisa_Vadeli_Yukumlulukler"
                ] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Profit",
              value: firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                "69_DONEM_KARI__ZARARI_"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                      "69_DONEM_KARI__ZARARI_"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Finansal_THP_Alanlari"]?.[
                  "69_DONEM_KARI__ZARARI_"
                ] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Total Equity",
              value: firmAnalysis?.result?.["Rasyolar"]?.[
                "B6_Ozsermaye_Buyukluk"
              ]?.[0]
                ? `$${formatCurrency(
                    firmAnalysis?.result?.["Rasyolar"]?.[
                      "B6_Ozsermaye_Buyukluk"
                    ]?.reduce((acc: number, index: number) => {
                      return acc + index;
                    }, 0),
                  )}`
                : "-",
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["B6_Ozsermaye_Buyukluk"] ||
                [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Total Debt Size",
              value: `$${formatCurrency(
                firmAnalysis?.result?.Rasyolar?.[
                  "B3_Toplam_Borc_Buyuklugu"
                ]?.[0] || 0,
              )}`,
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.[
                  "B3_Toplam_Borc_Buyuklugu"
                ] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Total Asset Size",
              value: `$${formatCurrency(
                firmAnalysis?.result?.Rasyolar?.["B5_Aktif_Buyukluk"]?.[0] || 0,
              )}`,
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["B5_Aktif_Buyukluk"] || [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
          <Col span={6}>
            {renderPdfChart({
              title: "Total Equity Size",
              value: `$${formatCurrency(
                firmAnalysis?.result?.Rasyolar?.[
                  "B6_Ozsermaye_Buyukluk"
                ]?.[0] || 0,
              )}`,
              chartData:
                firmAnalysis?.result?.["Rasyolar"]?.["B6_Ozsermaye_Buyukluk"] ||
                [],
              suffix: "$",
              formatter: formatCurrency,
            })}
          </Col>
        </Row>
        <Row style={{ marginTop: 100 }}>
          <Title level={3}>Overall Summary</Title>
          <TextWithNewLines
            text={
              llmAnalysis?.overall_summary ||
              "No overall summary analysis found."
            }
          />
        </Row>
      </div>
    </Card>
  );
};

export default Cover;

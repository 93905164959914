import React from "react";
import { ConfigProvider, Layout } from "antd";
import { Navigate, Route, Routes } from "react-router-dom";
import { Url } from "./constants/urls";
import SideMenu from "./containers/SideMenu";
import Header from "./containers/Header";
import Authentication from "./containers/Authentication";
import Products from "./containers/Products";
import FinAnalysis from "./containers/FinAnalysis";
import FinAnalysisResults from "./containers/FinAnalysis/Results";
import FinAnalysisResultsLiquidity from "./containers/FinAnalysis/Results/Liquidity";
import FinAnalysisResultsProfitability from "./containers/FinAnalysis/Results/Profitability";
import FinAnalysisResultsSolvency from "./containers/FinAnalysis/Results/Solvency";
import FinAnalysisResultsActivity from "./containers/FinAnalysis/Results/Activity";
import FinAnalysisResultsValuation from "./containers/FinAnalysis/Results/Valuation";
import Feedback from "./containers/Feedback";
import Firms from "./containers/Firms";
import { useApp } from "./context/app.context";

const { Content } = Layout;

const App: React.FC = () => {
  const { authUser, sideMenuCollapsed, toggleSideMenuCollapsed } = useApp();

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            headerColor: "#fff",
            ...(authUser && { headerPadding: "0 30px 0 0" }),
          },
        },
      }}
    >
      <Layout className="layout" style={{ minHeight: "100%", height: "auto" }}>
        {authUser && <SideMenu />}
        <Layout>
          <Header
            collapsed={sideMenuCollapsed}
            setCollapsed={toggleSideMenuCollapsed}
          />
          <Content style={{ padding: "30px 50px" }}>
            {!authUser ? (
              <Authentication />
            ) : (
              <>
                <Routes>
                  <Route path={Url.ProductsRoute} element={<Products />} />
                  <Route
                    path={Url.FinAnalysisRoute}
                    element={<FinAnalysis />}
                  />
                  <Route
                    path={Url.FinAnalysisResultsRoute}
                    element={<FinAnalysisResults />}
                  />
                  <Route
                    path={Url.FinAnalysisResultsLiquidityRoute}
                    element={<FinAnalysisResultsLiquidity />}
                  />
                  <Route
                    path={Url.FinAnalysisResultsProfitabilityRoute}
                    element={<FinAnalysisResultsProfitability />}
                  />
                  <Route
                    path={Url.FinAnalysisResultsSolvencyRoute}
                    element={<FinAnalysisResultsSolvency />}
                  />
                  <Route
                    path={Url.FinAnalysisResultsActivityRoute}
                    element={<FinAnalysisResultsActivity />}
                  />
                  <Route
                    path={Url.FinAnalysisResultsValuationRoute}
                    element={<FinAnalysisResultsValuation />}
                  />
                  <Route path={Url.FirmsRoute} element={<Firms />} />
                  {/* Redirect all other routes to BaseRoute */}
                  <Route
                    path="*"
                    element={<Navigate to={Url.ProductsRoute} replace />}
                  />
                </Routes>
                <Feedback />
              </>
            )}
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default App;

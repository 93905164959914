import { message } from "antd";
import {
  IFirmAnalysisRequest,
  IFirmAnalysis,
} from "../../containers/FinAnalysis/types";
import BackendClient from "../BackendClient";
import { FirmAnalysisUrl } from "../constants/urls";
// import { mockFirmAnalyses } from "../../containers/FinAnalysis/mocks";

export const createFirmAnalysisRequest = async (
  payload: IFirmAnalysisRequest,
): Promise<IFirmAnalysis> => {
  try {
    const formData = new FormData();
    formData.append("firm_id", payload.firm_id.toString());
    formData.append("file", payload.file);

    const { data } = await BackendClient.post(
      FirmAnalysisUrl.CreateFirmAnalysis,
      formData,
    );
    message.success("Firm analysis created successfully");
    return data;
  } catch (error) {
    console.error(error);
    message.error("Failed to create firm analysis. Please try again later.");
    throw error;
  }
};

export const fetchFirmAnalysesRequest = async (
  id: number,
): Promise<IFirmAnalysis[]> => {
  try {
    const { data } = await BackendClient.get(
      FirmAnalysisUrl.GetFirmAnalyses({ id: id.toString() }),
    );
    return data;
    // return mockFirmAnalyses.filter((analysis) => analysis.firmId === id);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchFirmAnalysisRequest = async (
  id: number,
): Promise<IFirmAnalysis> => {
  try {
    const { data } = await BackendClient.get(
      FirmAnalysisUrl.GetFirmAnalysis({ id: id.toString() }),
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteFirmAnalysisRequest = async (id: number): Promise<void> => {
  try {
    await BackendClient.delete(
      FirmAnalysisUrl.DeleteFirmAnalysis({ id: id.toString() }),
    );
    message.success("Firm analysis deleted successfully");
  } catch (error) {
    console.error(error);
    message.error("Failed to delete firm analysis. Please try again later.");
    throw error;
  }
};

import React from "react";
import { Row } from "antd";
import { useNavigate } from "react-router-dom";
import { Url } from "../../constants/urls";
import ProductCard from "../../components/ProductCard";
import { PlayCircleOutlined, DeploymentUnitOutlined } from "@ant-design/icons";

const Products: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Row gutter={16}>
      <ProductCard
        icon={<PlayCircleOutlined style={{ fontSize: 30 }} />}
        title="FinAnalysis"
        description="Description of the FinAnalysis"
        onClick={() => navigate(Url.FinAnalysisRoute.replace("/:firmId?", ""))}
      />
      <ProductCard
        icon={<DeploymentUnitOutlined style={{ fontSize: 30 }} />}
        title="IntelliCollect"
        description="Description of the IntelliCollect"
      />
    </Row>
  );
};

export default Products;

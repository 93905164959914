import React from "react";
import { Gauge } from "@ant-design/plots";

interface GaugeData {
  target: number;
  total: number;
  name?: string;
  thresholds?: number[];
}

interface GaugeChartProps {
  data: GaugeData;
  autoFit?: boolean;
  legend?: boolean;
  colorRange?: string[];
  title?: string;
  yAxisDescription?: string;
  height?: number;
  marginTop?: number;
}

const GaugeChart: React.FC<GaugeChartProps> = ({
  data,
  autoFit = true,
  legend = false,
  colorRange = ["#F4664A", "#FAAD14", "green"],
  title,
  yAxisDescription,
  height,
  marginTop = 0,
}) => {
  // Use the props to configure the Gauge chart
  const config = {
    autoFit,
    data,
    legend,
    scale: {
      color: {
        range: colorRange,
      },
    },
    style: {
      textContent: (target: any, total: any) =>
        `Score: ${target} (${parseInt(String((target / total) * 100), 10)}%)`,
    },
    title: {
      title,
      align: "center",
    },
    height,
  };

  return (
    <div style={{ marginTop }}>
      <Gauge {...config} />
    </div>
  );
};

export default GaugeChart;

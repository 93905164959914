import axios, { AxiosRequestConfig } from "axios";
import { message } from "antd";

import constants from "../constants";
import { UserStorage, AuthUser } from "./UserStorage";

const backendInstance = axios.create({
  baseURL: constants.BaseUrl,
});

backendInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Check if the error response and data exist
    if (error.response && error.response.data) {
      let errorMessages: string[] = [];

      // Check if error.response.data is an object and iterate over its keys to extract messages
      if (
        typeof error.response.data === "object" &&
        !Array.isArray(error.response.data)
      ) {
        Object.keys(error.response.data).forEach((key) => {
          // Concatenate all messages from each key to the errorMessages array
          errorMessages = errorMessages.concat(error.response.data[key]);
        });
      }

      // Join all collected error messages into a single string to display
      if (errorMessages.length > 0) {
        const errorMessage = errorMessages.join(" ");
        console.error("Error:", errorMessage);
        message.error(errorMessage); // Displaying error message using your frontend notification system
        return Promise.reject(errorMessage);
      }
    }

    // Fallback to generic error.message if no specific messages are found
    const fallbackMessage = error.message || "An unknown error occurred";
    console.error("Error:", fallbackMessage);
    message.error(fallbackMessage);
    return Promise.reject(fallbackMessage);
  },
);

class Backend {
  private getConfig(): AxiosRequestConfig {
    return {
      headers: {
        ...(UserStorage.isAuthenticated() && {
          Authorization: "Bearer " + UserStorage.getUser(AuthUser.AccessToken),
        }),
      },
    };
  }

  public async delete(url: string) {
    return backendInstance.delete(url, this.getConfig());
  }
  public async get(url: string, config?: any) {
    return backendInstance.get(url, { ...this.getConfig(), ...config });
  }
  public async patch(url: string, data?: any) {
    return backendInstance.patch(url, data, this.getConfig());
  }
  public async post(url: string, data?: any, config?: any) {
    return backendInstance.post(url, data, { ...this.getConfig(), ...config });
  }
  public async put(url: string, data?: any) {
    return backendInstance.put(url, data, this.getConfig());
  }
}

const BackendClient = new Backend();

export default BackendClient;

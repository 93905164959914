import {
  ILogin,
  IAuthResponse,
} from "../../containers/Authentication/types/authentication";
import BackendClient from "../BackendClient";
import { AuthenticationUrl } from "../constants/urls";

export const loginRequest = async (payload: ILogin): Promise<IAuthResponse> => {
  try {
    const { data } = await BackendClient.post(AuthenticationUrl.Login, payload);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

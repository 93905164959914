import { message } from "antd";
import { IFirmRequest, IFirm } from "../../containers/Firms/types";
import BackendClient from "../BackendClient";
import { FirmsUrl } from "../constants/urls";

export const createFirmRequest = async (
  payload: IFirmRequest,
): Promise<IFirm> => {
  try {
    const { data } = await BackendClient.post(FirmsUrl.CreateFirm, payload);
    message.success("Firm created successfully");
    return data;
  } catch (error) {
    console.error(error);
    message.error("Failed to create firm. Please try again later.");
    throw error;
  }
};

export const fetchFirmsRequest = async (): Promise<IFirm[]> => {
  try {
    const { data } = await BackendClient.get(FirmsUrl.GetFirms);
    return data;
  } catch (error) {
    message.error("Failed to fetch firms. Please try again later.");
    console.error(error);
    throw error;
  }
};

export const deleteFirmRequest = async (id: number): Promise<void> => {
  try {
    await BackendClient.delete(FirmsUrl.DeleteFirm({ id: id.toString() }));
    message.success("Firm deleted successfully");
  } catch (error) {
    console.error(error);
    message.error("Failed to delete firm. Please try again later.");
    throw error;
  }
};

export const updateFirmRequest = async (
  id: number,
  payload: IFirmRequest,
): Promise<IFirm> => {
  try {
    const { data } = await BackendClient.patch(
      FirmsUrl.UpdateFirm({ id: id.toString() }),
      payload,
    );
    message.success("Firm updated successfully");
    return data;
  } catch (error) {
    console.error(error);
    message.error("Failed to update firm. Please try again later.");
    throw error;
  }
};

export const fetchFirmRequest = async (id: number): Promise<IFirm> => {
  try {
    const { data } = await BackendClient.get(
      FirmsUrl.GetFirm({ id: id.toString() }),
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
